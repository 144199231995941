import { getLanguageNameDisplay } from "../../utils/languageUtils";
import { LanguageOptions, SettingButtonData, UnitsOptions } from "./types";

export const LanguageSettings: SettingButtonData[] = [
  {
    buttonText: getLanguageNameDisplay(LanguageOptions.English),
    buttonValue: LanguageOptions.English,
  },
  {
    buttonText: getLanguageNameDisplay(LanguageOptions.French),
    buttonValue: LanguageOptions.French,
  },
];

export const UnitsSettings: SettingButtonData[] = [
  {
    buttonText: "userProfile.imperialUnitsSelection",
    buttonValue: UnitsOptions.Imperial,
  },
  {
    buttonText: "userProfile.metricUnitsSelection",
    buttonValue: UnitsOptions.Metric,
  },
];
