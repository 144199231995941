import { LanguageOptions } from "../components/userProfileSettings/types";

/* Language names are not internationalized because if I want English,
    it should appear as "English", not "영어" or "Anglais". */
export const getLanguageNameDisplay = (
  languageSetting: LanguageOptions,
): string => {
  if (languageSetting === LanguageOptions.French) {
    return "Français";
  }

  return "English";
};
