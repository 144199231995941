import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import translations from "./i18n";

import "./index.scss";

import {
  PermissionProvider,
  RequireAuth,
  UserProvider,
} from "@ab-inbev/sam-auth";
import { setupRUM } from "@ab-inbev/sam-monitoring";
import { setupLogging } from "@ab-inbev/sam-logging";
import { setupI18n } from "@ab-inbev/sam-i18n";
import { applicationRoleConfig } from "./config/permissions";
import { FeatureFlagProvider } from "@ab-inbev/sam-utils";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

setupRUM({
  applicationId: import.meta.env.VITE_DD_APP_ID,
  clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
  env: import.meta.env.MODE,
  service: import.meta.env.VITE_DD_SERVICE,
  app: import.meta.env.VITE_APP_NAME,
  version: import.meta.env.VITE_CODE_VERSION,
  site: import.meta.env.VITE_DD_SITE,
  apiUrl: import.meta.env.VITE_SAM_APIM_URL,
  enableRUM: import.meta.env.VITE_DD_ENABLE_RUM,
});

setupLogging({
  clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
  env: import.meta.env.MODE,
  service: import.meta.env.VITE_DD_SERVICE,
  app: import.meta.env.VITE_APP_NAME,
  version: import.meta.env.VITE_CODE_VERSION,
  site: import.meta.env.VITE_DD_SITE,
  enableLogging: import.meta.env.VITE_DD_ENABLE_LOGGING,
});

const { i18n, I18nextProvider } = setupI18n(translations);
const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <React.Suspense fallback={null}>
      <UserProvider>
        <RequireAuth
          clientId={import.meta.env.VITE_CLIENT_ID}
          tenantId={import.meta.env.VITE_TENANT_ID}
          scopes={[import.meta.env.VITE_SAM_SCOPE]}
        >
          <I18nextProvider i18n={i18n}>
            <QueryClientProvider client={queryClient}>
              <PermissionProvider
                permissions={applicationRoleConfig.permissions}
                roleMapping={applicationRoleConfig.roleMapping}
              >
                <FeatureFlagProvider>
                  <App />
                </FeatureFlagProvider>
              </PermissionProvider>
            </QueryClientProvider>
          </I18nextProvider>
        </RequireAuth>
      </UserProvider>
    </React.Suspense>
  </React.StrictMode>,
);
