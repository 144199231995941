import {
  ABIAppButton,
  AppButtonVariant,
} from "@ab-inbev-labs/ux-react-components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SettingButtonData } from "./types";
import "../UserProfile.scss";

type UserSettingButtonsProps = {
  settingButtonData: SettingButtonData[];
  currentSetting: string;
  updateCurrentSetting: (selectedOption: string) => void;
};

export const UserSettingButtons: FC<UserSettingButtonsProps> = ({
  settingButtonData,
  currentSetting,
  updateCurrentSetting,
}) => {
  const i18next = useTranslation();

  return (
    <div>
      {settingButtonData.map((setting, index) => {
        const matchesCurrent = setting.buttonValue === currentSetting;

        return (
          <ABIAppButton
            key={`setting-button-${setting.buttonValue}`}
            className={`user-profile-setting-accordion-button ${
              matchesCurrent ? "current-setting-selection" : ""
            }`}
            disabled={matchesCurrent}
            variant={AppButtonVariant.Tertiary}
            onClick={() => updateCurrentSetting(setting.buttonValue)}
          >
            {i18next.t(setting.buttonText)}
          </ABIAppButton>
        );
      })}
    </div>
  );
};
