/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetBrewingSpecialsSettingsResponse } from '../models/GetBrewingSpecialsSettingsResponse';
import type { SaveBrewingSpecialsNotificationRequest } from '../models/SaveBrewingSpecialsNotificationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BrewingSpecialsSettingsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Brewing Specials settings
   * Gets the user's Brewing Specials settings
   * @returns GetBrewingSpecialsSettingsResponse Success
   * @throws ApiError
   */
  public brewingSpecialsSettingsGet(): CancelablePromise<GetBrewingSpecialsSettingsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/brewing-specials-settings',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Save Brewing Specials notifications
   * Subscribe or unsubscribe to a notification
   * @returns any Success
   * @throws ApiError
   */
  public brewingSpecialsSettingsSaveNotification({
requestBody,
}: {
requestBody?: SaveBrewingSpecialsNotificationRequest,
}): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/brewing-specials-settings/notifications',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
