import { Dispatch, SetStateAction, createContext } from "react";

type SidebarContextType = {
  doesCurrentApplicationUseSidebar: boolean;
  setDoesCurrentApplicationUseSidebar: Dispatch<SetStateAction<boolean>>;
};

export const SidebarContext = createContext<SidebarContextType>({
  doesCurrentApplicationUseSidebar: false,
  setDoesCurrentApplicationUseSidebar: (x) => null,
});
