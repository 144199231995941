import { useMutation } from "@tanstack/react-query";
import { useUserProfileClient } from "../userProfileApiClient";
import { SaveGeneralSettingsRequest } from "../../../user-profile/codegen";

export const useGeneralSettingsSaveMutation = () => {
  const { createClient } = useUserProfileClient();

  return useMutation({
    mutationFn: async (payload: SaveGeneralSettingsRequest) =>
      (await createClient()).generalSettings.generalSettingsSave({
        requestBody: payload,
      }),
  });
};
