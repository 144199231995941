import { useQuery } from "@tanstack/react-query";
import { useUserProfileClient } from "../userProfileApiClient";
import { UserProfileQueryKeys } from "../userProfileQueryKeys";

export const useGeneralSettingsGetQuery = () => {
  const { createClient } = useUserProfileClient();

  return useQuery({
    queryKey: [UserProfileQueryKeys.UseGeneralSettingsGet],
    queryFn: async () =>
      (await createClient()).generalSettings.generalSettingsGet(),
    throwOnError: false,
    refetchOnWindowFocus: false,
    // refetchOnMount: false, // Prevents refetching when the component mounts or remount - used if issue persists with refetchOnWindowFocus
  });
};
