import { ABIIconButton, PowerIcon } from "@ab-inbev-labs/ux-react-components";
import { UserContext, useMsalInstance } from "@ab-inbev/sam-auth";
import { FC, useContext } from "react";

export const LogoutButton: FC = () => {
  const userContext = useContext(UserContext);

  const msalInstance = useMsalInstance(
    import.meta.env.VITE_CLIENT_ID,
    import.meta.env.VITE_TENANT_ID,
    userContext,
  );

  return (
    <ABIIconButton
      className="logout-button"
      onClick={() =>
        msalInstance.logoutRedirect({
          postLogoutRedirectUri: `${window.location.origin}/apps`,
        })
      }
    >
      <PowerIcon color="var(--color-action-default)" height={18} width={18} />
    </ABIIconButton>
  );
};
