import { useGetToken } from "@ab-inbev/sam-auth";
import { UserProfileApiClient } from "../../user-profile/codegen";
import { OpenAPIConfig } from "../../user-profile/codegen/core/OpenAPI";

export const useUserProfileClient = () => {
  const getTokenFromHook = useGetToken();

  const createClient = async () => {
    const token = await getTokenFromHook([import.meta.env.VITE_SAM_SCOPE]);
    if (!token) {
      console.warn("Failed to acquire token");
    }

    const config: Partial<OpenAPIConfig> = {
      BASE: `${import.meta.env.VITE_SAM_APIM_URL as string}/user-profile`,
      TOKEN: token || "",
    };

    return new UserProfileApiClient(config);
  };

  return { createClient };
};
