import { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const FallbackRouteHandler = (props: PropsWithChildren) => {
  const navigate = useNavigate();

  const routeTo = () =>
    new URLSearchParams(window.location.search).get("routeTo");

  // This component wraps the content of every app. Before rendering the app, first
  // check if we were redirected here from the fallback handler with the `routeTo`
  // query param. If so, we need to navigate to the client-side route that the user
  // tried to load originally.
  useEffect(() => {
    if (routeTo()) navigate(routeTo() || "");
  });

  // If there is no `routeTo` param, then this is normal operation, so simply render children
  return !routeTo() && props.children;
};

export default FallbackRouteHandler;
