import { UseQueryResult } from "@tanstack/react-query";
import { useEffect } from "react";

// Hook that takes a react-query UseQueryResult. If the query was sucessful, it does nothing and
// re-returns the result. If the query failed, the failure is console logged, and the provided
// fallback data is returned in place of the actual query data.
// Make sure to use `throwOnError: false` when passing the result to this hook.
// Ex.
//     const result = useBrandListQuery(select, false);
//     return useQueryFallBack(
//       result,
//       fallbackData,
//       "Brewdat GET brands (useBrandListQueryWithFallback)",
//     );
export const useQueryFallBack = <TData>(
  queryResult: UseQueryResult<TData, Error>,
  fallbackData: TData,
  requestNameToLog: string,
) => {
  useEffect(() => {
    if (queryResult.isError) {
      console.warn(
        `API request ${requestNameToLog} was unsuccessful, and the static fallback data was used.`,
      );
    }
  }, [queryResult.error, queryResult.isError, requestNameToLog]);

  return {
    ...queryResult,
    data: queryResult.isSuccess ? queryResult.data : fallbackData,
  };
};
