import { useQuery } from "@tanstack/react-query";
import { useUserProfileClient } from "../userProfileApiClient";
import { UserProfileQueryKeys } from "../userProfileQueryKeys";

export const useFeaturesGetQuery = () => {
  const { createClient } = useUserProfileClient();

  return useQuery({
    queryKey: [UserProfileQueryKeys.UseFeaturesGet],
    queryFn: async () => (await createClient()).featureFlags.featuresGet(),
    throwOnError: false,
    refetchOnWindowFocus: false,
    // refetchOnMount: false, // Prevents refetching when the component mounts or remount - used if issue persists with refetchOnWindowFocus
  });
};
