const resources = {
  "en-US": {
    translation: {
      appName: "Supply Applications",
      searchApps: "Search Apps",
    },
  },
  "fr-CA": {
    translation: {
      appName: "Applications Supply",
      searchApps: "Rechercher des Applications",
    },
  },
};

export default resources;
