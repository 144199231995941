import React from "react";
import "./App.scss";
import {
  ABIAppButton,
  AppButtonVariant,
  RoundOrSquare,
} from "@ab-inbev-labs/ux-react-components";

type AppCardProps = {
  title: string;
  description: string;
  path: string;
  // headerHeight: number;
  ctaLabel: string;
};

const AppCard = ({ title, description, path, ctaLabel }: AppCardProps) => {
  const handleClick = () => {
    window.location.assign(`${window.location.origin}/${path}`);
  };

  return (
    <div className="apps-grid-card">
      <div className="apps-card-header-wrap" title={title}>
        <div className="apps-card-header-item">{title}</div>
      </div>
      <div className="apps-card-description">{description}</div>
      <div className="apps-card-cta">
        <ABIAppButton
          variant={AppButtonVariant.Primary}
          corner={RoundOrSquare.Square}
          onClick={handleClick}
        >
          {ctaLabel}
        </ABIAppButton>
      </div>
    </div>
  );
};

export default AppCard;
