import { Dispatch, SetStateAction, createContext } from "react";

type MutationErrorContextType = {
  isMutationErrorModalShown: boolean;
  setIsMutationErrorModalShown: Dispatch<SetStateAction<boolean>>;
  mutationErrorMessage: string;
  setMutationErrorMessage: Dispatch<SetStateAction<string>>;
};

export const MutationErrorContext = createContext<MutationErrorContextType>({
  isMutationErrorModalShown: false,
  setIsMutationErrorModalShown: (x) => null,
  mutationErrorMessage: "",
  setMutationErrorMessage: (x) => null,
});
