import Layout from "@ab-inbev/sam-layout";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import React, { FC, PropsWithChildren, ReactElement } from "react";
import { useTranslation } from "react-i18next";

type LayoutWithLoaderProps = {
  contentClassName?: string;
  showUserProfile?: boolean;
  additionalUserProfileSettings?: ReactElement;
};

// This is a separate component because using the `useIsFetching/Mutating` hooks in App.tsx
// causes the app to constantly re-render and re-fetch as queries finish
export const LayoutWithLoader: FC<PropsWithChildren<LayoutWithLoaderProps>> = ({
  children,
  contentClassName,
  showUserProfile = true,
  additionalUserProfileSettings,
}) => {
  const { t } = useTranslation();

  // #region Global Loading
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const isGlobalLoadingDisplayed = isFetching > 0 || isMutating > 0;
  // #endregion

  return (
    <Layout
      appTitle={t("appName")}
      contentClassName={contentClassName}
      isGlobalLoadingDisplayed={isGlobalLoadingDisplayed}
      showUserProfile={showUserProfile}
      additionalUserProfileSettings={additionalUserProfileSettings}
    >
      {children}
    </Layout>
  );
};
