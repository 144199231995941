import { useBrewDatClient } from "../brewDatApiClient";
import { loadAndFormatApiBreweriesList } from "../breweryDataConsts";
import { stringArrayToSelectOptions } from "@ab-inbev/sam-utils";
import { view_plant } from "../../../abi-brewdat-naz-supply/codegen";
import { useQuery } from "@tanstack/react-query";
import { useQueryFallBack } from "../../useQueryFallback";

// Transform data for use in search bar
export const selectBreweryListAsSearchBar = (data: view_plant[]) =>
  loadAndFormatApiBreweriesList(data);

// Transform data for use in select dropdown
export const selectBreweryListAsSelectOptions = (data: view_plant[]) =>
  stringArrayToSelectOptions(loadAndFormatApiBreweriesList(data));

export const useBreweryListQuery = <TData>(
  select?: (data: view_plant[]) => TData,
  throwOnError = true,
) => {
  const { createClient } = useBrewDatClient();
  return useQuery({
    queryKey: ["useBreweryListQuery"],
    queryFn: async () =>
      (await createClient()).abiBrewdatNazSupplyViews.getViewPlant({
        resultsLimit: 500,
      }),
    select,
    throwOnError,
  });
};

export const useBreweryListQueryWithFallback = <TData>(
  fallbackData: TData,
  select?: (data: view_plant[]) => TData,
) => {
  const result = useBreweryListQuery(select, false);
  return useQueryFallBack(
    result,
    fallbackData,
    "Brewdat GET breweries (useBreweryListQueryWithFallback)",
  );
};
