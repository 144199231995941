import { ApiError } from "@ab-inbev/sam-api";
import { MutationErrorContext } from "@ab-inbev/sam-layout";
import { QueryClientConfig, useQueryClient } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useContext } from "react";

const isApiErrorUnexpected = (error: Error) => {
  const apiError = error as ApiError;
  const expectedHttpCodes = apiError?.request?.errors;
  const receivedHttpCode = apiError?.status;

  // Indicate that an API error is unexpected (cannot be handled):
  //   - receivedHttpCode is undefined - this indicates that we did not receive an
  // ApiError, meaning some other network or server issue occurred.
  //   - API returned 401 or 403 (auth failures) - the front end knows the login status
  // and user permissions, so should never allow a call that would be rejected. If this
  // happens, something else has gone quite wrong.
  //   - API returned any other status that was not defined in the API spec as an expected
  // error, such as a 400 due to a business logic validation failure. Calling components
  // are expected to handle the expected errors if necessary.
  return (
    !receivedHttpCode ||
    receivedHttpCode === 401 ||
    receivedHttpCode === 403 ||
    (!!expectedHttpCodes && !expectedHttpCodes[receivedHttpCode])
  );
};

// Don't retry queries or propagate the errors to the error boundary if
// they are a "valid" error, e.g. a 404 from the API for a non-existent ID.
// Legitimate network failures will be caught by the ErrorBoundary component,
// while "valid" errors such as 404s need to be handled by lower, more business-
// logic-aware components.
// Mutations never use the error boundary, as this would leave an unhandled
// exception that would prevent the user from going back to their work. Instead,
// simply show an error modal on mutation error and act like it never happened.
const retry = (failureCount: number, error: unknown) =>
  failureCount < 2 && isApiErrorUnexpected(error as Error);

const apiQueryClientConfig = (
  setIsMutationErrorModalShown: Dispatch<SetStateAction<boolean>>,
  setMutationErrorMessage: Dispatch<SetStateAction<string>>,
): QueryClientConfig => ({
  defaultOptions: {
    queries: {
      retry,
      throwOnError: (error) => isApiErrorUnexpected(error),
      refetchOnWindowFocus: false,
      staleTime: 60000,
    },
    mutations: {
      retry,
      onError: (error: unknown) => {
        console.error(JSON.stringify(error, null, 2));
        setIsMutationErrorModalShown(true);
        const err = error as { body: { errors: object } };
        setMutationErrorMessage(
          err && err.body && err.body.errors
            ? JSON.stringify(err.body.errors)
            : "",
        );
      },
    },
  },
});

// This hook sets the react-query QueryClient configuration. This cannot be done statically
// outside the app (such as in main/index.tsx) as it needs access to the mutation error context.
// The context provider is the Layout component, so this configurer must be called below the Layout.
export const useConfigureQueryClient = () => {
  const { setIsMutationErrorModalShown, setMutationErrorMessage } =
    useContext(MutationErrorContext);
  const qc = useQueryClient();
  qc.setDefaultOptions(
    apiQueryClientConfig(setIsMutationErrorModalShown, setMutationErrorMessage)
      .defaultOptions || {},
  );
};

// Component wrapper for the above hook
export const QueryClientConfigurer = () => {
  useConfigureQueryClient();
  return null;
};
