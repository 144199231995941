/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Feature } from '../models/Feature';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FeatureFlagsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns true if the feature is enabled; false otherwise.
   * Determine if a feature flag is enabled or disabled.
   * @returns boolean Success
   * @throws ApiError
   */
  public featuresEnabled({
name,
}: {
name: string,
}): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/features/{name}/enabled',
      path: {
        'name': name,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Returns the status of all defined features.
   * Returns the status for each defined feature. If a feature is not provided, the feature can be assumed to be disabled.
   * @returns Feature Success
   * @throws ApiError
   */
  public featuresGet(): CancelablePromise<Array<Feature>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/features',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
