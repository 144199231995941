/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AbiBrewdatNazSupplyActionsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Run SQL query
   * Run SQL query
   * @returns any successful operation
   * @throws ApiError
   */
  public postQuery({
resultsLimit = 100,
nested = 'false',
apiNestedFormatProperties = 'false',
requestBody,
}: {
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
/**
 * Custom SQL query
 */
requestBody?: {
query: string;
},
}): CancelablePromise<Array<Record<string, any>>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/query',
      headers: {
        'results-limit': resultsLimit,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        500: `Error running query`,
      },
    });
  }

}
