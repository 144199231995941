// eslint-disable-next-line no-restricted-imports
import { datadogRum as ddRumModule } from "@datadog/browser-rum";
// eslint-disable-next-line no-restricted-imports
import type { RumGlobal } from "@datadog/browser-rum";

declare global {
  interface Window {
    DD_RUM: RumGlobal;
  }
}

export const datadogRUM: RumGlobal = window.DD_RUM ?? ddRumModule;

type SetupRUMConfig = {
  applicationId: string;
  clientToken: string;
  site: string;
  version: string;
  service: string;
  env: string;
  app: string;
  sessionSampleRate?: number;
  sessionReplaySampleRate?: number;
  traceSampleRate?: number;
  apiUrl?: string;
  enableRUM: string;
};

export const setupRUM: (config: SetupRUMConfig) => typeof datadogRUM = (
  config,
) => {
  if (!datadogRUM.getInitConfiguration() && config.enableRUM === "true") {
    datadogRUM.init({
      version: config.version,
      applicationId: config.applicationId,
      clientToken: config.clientToken,
      site: config.site,
      service: config.service,
      env: config.env,
      sessionSampleRate: config.sessionSampleRate ?? 100,
      sessionReplaySampleRate: config.sessionReplaySampleRate ?? 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",

      // https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces
      allowedTracingUrls: config.apiUrl
        ? [
            {
              match: config.apiUrl,
              propagatorTypes: ["datadog", "tracecontext"],
            },
          ]
        : undefined,
      traceSampleRate: config.traceSampleRate ?? 100,
    });

    datadogRUM.setGlobalContextProperty("app", config.app);
  }

  return datadogRUM;
};
