import { useEffect } from "react";

// Redirect component as an arrow function
export const ExternalRedirect = ({ to }: { to: string }) => {
  useEffect(() => {
    // Redirect to the external URL
    window.location.href = to;
  }, [to]);

  return null; // This component does not render anything
};
