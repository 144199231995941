import { useDocumentTitle } from "@ab-inbev-labs/ux-react-components";
import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { QueryClientConfigurer } from "../QueryClientConfig";
import { LayoutWithLoader } from "./LayoutWithLoader";

type RootProps = {
  contentClassName?: string;
  additionalUserProfileSettings?: ReactElement;
};

export const Root: FC<RootProps> = ({
  contentClassName,
  additionalUserProfileSettings,
}) => {
  const { t } = useTranslation();
  useDocumentTitle({ title: `ABI Supply | ${t("appName")}` });

  // Wrap everything with Layout
  return (
    <LayoutWithLoader
      contentClassName={contentClassName}
      additionalUserProfileSettings={additionalUserProfileSettings}
    >
      {/* Configure query client here, as it is the highest place in the app under the Layout */}
      <QueryClientConfigurer />
      <Outlet />
    </LayoutWithLoader>
  );
};
