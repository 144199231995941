import { BrewdatNazSupplyApiClient } from "../../abi-brewdat-naz-supply/codegen";
import { OpenAPIConfig } from "../../abi-brewdat-naz-supply/codegen/core/OpenAPI";
import { useGetToken } from "@ab-inbev/sam-auth";

export const useBrewDatClient = () => {
  const getTokenFromHook = useGetToken();

  const createClient = async () => {
    const token = await getTokenFromHook([import.meta.env.VITE_BREWDAT_SCOPE]);
    if (!token) {
      console.warn("Failed to acquire token");
    }

    const config: Partial<OpenAPIConfig> = {
      TOKEN: token || "",
      BASE: `${import.meta.env.VITE_BREWDAT_APIM_URL as string}`,
    };

    return new BrewdatNazSupplyApiClient(config);
  };

  return { createClient };
};
