import { useEffect, useState } from "react";

export enum LayoutSize {
  Desktop,
  Tablet,
  Mobile,
}

export const useLayoutSize = () => {
  const getLayoutSize = () => {
    if (window.innerWidth > 1000) return LayoutSize.Desktop;
    else if (window.innerWidth > 700) return LayoutSize.Tablet;
    else return LayoutSize.Mobile;
  };

  const [layoutSize, setLayoutSize] = useState(getLayoutSize());

  useEffect(() => {
    function handleResize() {
      setLayoutSize(getLayoutSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return layoutSize;
};
