import { RouteObject } from "react-router-dom";
import { ExternalRedirect } from "../components/ExternalRedirect";
import { AppRouteNotFound } from "../components/AppRouteNotFound";
import Layout from "../Layout";

export const defaultRouteConfig = (appTitle: string): RouteObject[] => [
  {
    path: "*",
    element: (
      <Layout appTitle={appTitle}>
        <AppRouteNotFound appTitle={appTitle} />
      </Layout>
    ),
  },
  {
    path: "/",
    element: <ExternalRedirect to={`${window.location.origin}`} />,
  },
  {
    path: "/apps",
    element: <ExternalRedirect to={`${window.location.origin}/apps`} />,
  },
];
