import React, { createContext, ReactNode } from "react";
import { useFeaturesGetQuery } from "../../api/clients/user-profile-api-client";
import { Feature } from "../../api/user-profile/codegen";

export const FeatureFlagContext = createContext<{
  featureFlags: Feature[] | undefined;
}>({ featureFlags: [] }); // default values

export const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
  const { data: featureFlags, isLoading, isFetching } = useFeaturesGetQuery();

  if (!isLoading && !isFetching) {
    return (
      <FeatureFlagContext.Provider value={{ featureFlags }}>
        {children}
      </FeatureFlagContext.Provider>
    );
  }
};
