import { ApplicationRoleMapping } from "@ab-inbev/sam-auth";

export const applicationRoleConfig: ApplicationRoleMapping = {
  permissions: {
    AppAllAdmin: {},
  },
  roleMapping: {
    "App.All.Admin": "AppAllAdmin",
  },
};
