/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { view_age_cmpnt } from '../models/view_age_cmpnt';
import type { view_age_cmpnt_hist } from '../models/view_age_cmpnt_hist';
import type { view_brewdat_fof } from '../models/view_brewdat_fof';
import type { view_brewdat_fof_last_24 } from '../models/view_brewdat_fof_last_24';
import type { view_budgethours } from '../models/view_budgethours';
import type { view_budgetspeedhours } from '../models/view_budgetspeedhours';
import type { view_container_vw } from '../models/view_container_vw';
import type { view_dimequipmentstate } from '../models/view_dimequipmentstate';
import type { view_equipmentmaster } from '../models/view_equipmentmaster';
import type { view_events } from '../models/view_events';
import type { view_fault_events_brewery } from '../models/view_fault_events_brewery';
import type { view_oracle_fof } from '../models/view_oracle_fof';
import type { view_plant } from '../models/view_plant';
import type { view_productbrewing } from '../models/view_productbrewing';
import type { view_prt_cd_dtl_hist } from '../models/view_prt_cd_dtl_hist';
import type { view_prt_cd_hist } from '../models/view_prt_cd_hist';
import type { view_stck_cd_matl_hist } from '../models/view_stck_cd_matl_hist';
import type { view_vbl_cmpnt_hist } from '../models/view_vbl_cmpnt_hist';
import type { view_vw_base_package } from '../models/view_vw_base_package';
import type { view_vw_base_product } from '../models/view_vw_base_product';
import type { view_vw_brand_base_table } from '../models/view_vw_brand_base_table';
import type { view_vw_codes_base } from '../models/view_vw_codes_base';
import type { view_vw_mbew_base_table } from '../models/view_vw_mbew_base_table';
import type { view_vw_pkg_bom_zbs } from '../models/view_vw_pkg_bom_zbs';
import type { view_vw_pkg_supt_matl } from '../models/view_vw_pkg_supt_matl';
import type { view_vw_pkg_vndr_base } from '../models/view_vw_pkg_vndr_base';
import type { view_vw_prod_cont_stck_cd_hist_zbs } from '../models/view_vw_prod_cont_stck_cd_hist_zbs';
import type { view_vw_prod_pkg_base } from '../models/view_vw_prod_pkg_base';
import type { view_vw_stock_cd_base } from '../models/view_vw_stock_cd_base';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AbiBrewdatNazSupplyViewsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Query view age_cmpnt
   * View - Age Cmpnt Table (Source Oracle) 
   * @returns view_age_cmpnt successful operation
   * @throws ApiError
   */
  public getViewAgeCmpnt({
actnCd,
ageCmpntDsc,
ageCmpntDyCnt,
ageCmpntId,
ageCmpntPrtCd,
lastModId,
lastModTsp,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
actnCd?: string,
ageCmpntDsc?: string,
/**
 * Format - int32.
 */
ageCmpntDyCnt?: number,
/**
 * Format - int32.
 */
ageCmpntId?: number,
ageCmpntPrtCd?: string,
lastModId?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
lastModTsp?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_age_cmpnt>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/age_cmpnt',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'ACTN_CD': actnCd,
        'AGE_CMPNT_DSC': ageCmpntDsc,
        'AGE_CMPNT_DY_CNT': ageCmpntDyCnt,
        'AGE_CMPNT_ID': ageCmpntId,
        'AGE_CMPNT_PRT_CD': ageCmpntPrtCd,
        'LAST_MOD_ID': lastModId,
        'LAST_MOD_TSP': lastModTsp,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view age_cmpnt_hist
   * View - Age Cmpnt Hist Table (Source Oracle)
   * @returns view_age_cmpnt_hist successful operation
   * @throws ApiError
   */
  public getViewAgeCmpntHist({
actIndCd,
ageCmpntDsc,
ageCmpntDyCnt,
ageCmpntId,
ageCmpntPrtCd,
lastModId,
lastModTsp,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
actIndCd?: string,
ageCmpntDsc?: string,
/**
 * Format - int32.
 */
ageCmpntDyCnt?: number,
/**
 * Format - int32.
 */
ageCmpntId?: number,
ageCmpntPrtCd?: string,
lastModId?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
lastModTsp?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_age_cmpnt_hist>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/age_cmpnt_hist',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'ACT_IND_CD': actIndCd,
        'AGE_CMPNT_DSC': ageCmpntDsc,
        'AGE_CMPNT_DY_CNT': ageCmpntDyCnt,
        'AGE_CMPNT_ID': ageCmpntId,
        'AGE_CMPNT_PRT_CD': ageCmpntPrtCd,
        'LAST_MOD_ID': lastModId,
        'LAST_MOD_TSP': lastModTsp,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view brewdat_fof
   * View - First on Fault Table (Source PLC data view Brewdat)
   * @returns view_brewdat_fof successful operation
   * @throws ApiError
   */
  public getViewBrewdatFof({
area,
cDate,
downTimeDate,
downTimeDuration,
equipment,
faultCode,
faultCodeProximity,
faultCount,
faultDuration,
faultEndTimeLocal,
faultStartTimeLocal,
firstEndState,
firstStartState,
fullEquipment,
otherFaults,
site,
stateEndTimeLocal,
stateStartDateLocal,
stateStartTimeLocal,
subArea,
warningCount,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
area?: string,
/**
 * Format - date (as full-date in RFC3339).
 */
cDate?: string,
/**
 * Format - date (as full-date in RFC3339).
 */
downTimeDate?: string,
/**
 * Format - double.
 */
downTimeDuration?: number,
equipment?: string,
faultCode?: string,
/**
 * Format - int32.
 */
faultCodeProximity?: number,
/**
 * Format - int64.
 */
faultCount?: number,
/**
 * Format - double.
 */
faultDuration?: number,
/**
 * Format - date-time (as date-time in RFC3339).
 */
faultEndTimeLocal?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
faultStartTimeLocal?: string,
firstEndState?: string,
firstStartState?: string,
fullEquipment?: string,
otherFaults?: string,
site?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
stateEndTimeLocal?: string,
/**
 * Format - date (as full-date in RFC3339).
 */
stateStartDateLocal?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
stateStartTimeLocal?: string,
subArea?: string,
/**
 * Format - int64.
 */
warningCount?: number,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_brewdat_fof>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/brewdat_fof',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'area': area,
        'c_date': cDate,
        'down_time_date': downTimeDate,
        'down_time_duration': downTimeDuration,
        'equipment': equipment,
        'fault_code': faultCode,
        'fault_code_proximity': faultCodeProximity,
        'fault_count': faultCount,
        'fault_duration': faultDuration,
        'fault_end_time_local': faultEndTimeLocal,
        'fault_start_time_local': faultStartTimeLocal,
        'first_end_state': firstEndState,
        'first_start_state': firstStartState,
        'full_equipment': fullEquipment,
        'other_faults': otherFaults,
        'site': site,
        'state_end_time_local': stateEndTimeLocal,
        'state_start_date_local': stateStartDateLocal,
        'state_start_time_local': stateStartTimeLocal,
        'sub_area': subArea,
        'warning_count': warningCount,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view budgethours
   * View - Budget Hours Table (Source SQL)
   * @returns view_budgethours successful operation
   * @throws ApiError
   */
  public getViewBudgethours({
areaCode,
bcsc,
budgetMonth,
budgetYear,
cle,
code,
con,
conv,
cot,
dateInterval,
daysInMonth,
dpa,
ec,
engr,
ept,
_let,
lt,
meet,
name,
nst,
past,
plantCode,
pm,
rup,
st,
su,
tst,
tt,
wec,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
areaCode?: string,
/**
 * Format - double.
 */
bcsc?: number,
/**
 * Format - int32.
 */
budgetMonth?: number,
/**
 * Format - int32.
 */
budgetYear?: number,
/**
 * Format - double.
 */
cle?: number,
code?: string,
/**
 * Format - double.
 */
con?: number,
/**
 * Format - double.
 */
conv?: number,
/**
 * Format - double.
 */
cot?: number,
dateInterval?: string,
/**
 * Format - int32.
 */
daysInMonth?: number,
/**
 * Format - double.
 */
dpa?: number,
/**
 * Format - double.
 */
ec?: number,
/**
 * Format - double.
 */
engr?: number,
/**
 * Format - double.
 */
ept?: number,
/**
 * Format - double.
 */
_let?: number,
/**
 * Format - double.
 */
lt?: number,
/**
 * Format - double.
 */
meet?: number,
name?: string,
/**
 * Format - double.
 */
nst?: number,
/**
 * Format - double.
 */
past?: number,
plantCode?: string,
/**
 * Format - double.
 */
pm?: number,
/**
 * Format - double.
 */
rup?: number,
/**
 * Format - double.
 */
st?: number,
/**
 * Format - double.
 */
su?: number,
/**
 * Format - double.
 */
tst?: number,
/**
 * Format - double.
 */
tt?: number,
/**
 * Format - double.
 */
wec?: number,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_budgethours>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/budgethours',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'AreaCode': areaCode,
        'BCSC': bcsc,
        'BudgetMonth': budgetMonth,
        'BudgetYear': budgetYear,
        'CLE': cle,
        'Code': code,
        'CON': con,
        'CONV': conv,
        'COT': cot,
        'DateInterval': dateInterval,
        'DaysInMonth': daysInMonth,
        'DPA': dpa,
        'EC': ec,
        'ENGR': engr,
        'EPT': ept,
        'LET': _let,
        'LT': lt,
        'MEET': meet,
        'Name': name,
        'NST': nst,
        'PAST': past,
        'PlantCode': plantCode,
        'PM': pm,
        'RUP': rup,
        'ST': st,
        'SU': su,
        'TST': tst,
        'TT': tt,
        'WEC': wec,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view budgetspeedhours
   * View - Budget Speed Hours (Source SQL)
   * @returns view_budgetspeedhours successful operation
   * @throws ApiError
   */
  public getViewBudgetspeedhours({
areaCode,
code,
kpiMonth,
kpiYear,
name,
plantCode,
ratedSpeed,
routingId,
routingNumber,
standardSpeed,
targetEcHours,
targetEptHours,
targetLtHours,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
areaCode?: string,
code?: string,
/**
 * Format - int32.
 */
kpiMonth?: number,
/**
 * Format - int32.
 */
kpiYear?: number,
name?: string,
plantCode?: string,
/**
 * Format - double.
 */
ratedSpeed?: number,
routingId?: string,
/**
 * Format - int32.
 */
routingNumber?: number,
/**
 * Format - double.
 */
standardSpeed?: number,
/**
 * Format - double.
 */
targetEcHours?: number,
/**
 * Format - double.
 */
targetEptHours?: number,
/**
 * Format - double.
 */
targetLtHours?: number,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_budgetspeedhours>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/budgetspeedhours',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'AreaCode': areaCode,
        'Code': code,
        'KPIMonth': kpiMonth,
        'KPIYear': kpiYear,
        'Name': name,
        'PlantCode': plantCode,
        'RatedSpeed': ratedSpeed,
        'RoutingID': routingId,
        'RoutingNumber': routingNumber,
        'StandardSpeed': standardSpeed,
        'TargetECHours': targetEcHours,
        'TargetEPTHours': targetEptHours,
        'TargetLTHours': targetLtHours,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view container_vw
   * View - Container (Source Oracle)
   * @returns view_container_vw successful operation
   * @throws ApiError
   */
  public getViewContainerVw({
beerAsepticPkgFlag,
beerPackageDraftCode,
cntrbodyBodyCode,
cntrclasClassCode,
cntrclosClosureCode,
cntrcolrColorCode,
cntrdcorDecorationCode,
cntrmatlMaterialCode,
cntrneckNeckCode,
cntrrtrnReturnCode,
cntrsizeSizeCode,
cntrtypeTypeCode,
containrActionCode,
containrApprovalFlag,
containrChangeCount,
containrComment,
containrEditStatus,
containrEffectiveDate,
containrId,
containrInactiveDate,
containrPostingDate,
containrQualifier,
containrStatusCode,
containrSystemFlag,
containrUserId,
containrVersionNo,
pgraphicGraphicCode,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
beerAsepticPkgFlag?: string,
beerPackageDraftCode?: string,
cntrbodyBodyCode?: string,
cntrclasClassCode?: string,
cntrclosClosureCode?: string,
cntrcolrColorCode?: string,
cntrdcorDecorationCode?: string,
cntrmatlMaterialCode?: string,
cntrneckNeckCode?: string,
cntrrtrnReturnCode?: string,
cntrsizeSizeCode?: string,
cntrtypeTypeCode?: string,
containrActionCode?: string,
containrApprovalFlag?: string,
/**
 * Format - int64.
 */
containrChangeCount?: number,
containrComment?: string,
containrEditStatus?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
containrEffectiveDate?: string,
containrId?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
containrInactiveDate?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
containrPostingDate?: string,
containrQualifier?: string,
containrStatusCode?: string,
containrSystemFlag?: string,
containrUserId?: string,
/**
 * Format - int64.
 */
containrVersionNo?: number,
pgraphicGraphicCode?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_container_vw>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/container_vw',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'BEER_ASEPTIC_PKG_FLAG': beerAsepticPkgFlag,
        'BEER_PACKAGE_DRAFT_CODE': beerPackageDraftCode,
        'CNTRBODY_BODY_CODE': cntrbodyBodyCode,
        'CNTRCLAS_CLASS_CODE': cntrclasClassCode,
        'CNTRCLOS_CLOSURE_CODE': cntrclosClosureCode,
        'CNTRCOLR_COLOR_CODE': cntrcolrColorCode,
        'CNTRDCOR_DECORATION_CODE': cntrdcorDecorationCode,
        'CNTRMATL_MATERIAL_CODE': cntrmatlMaterialCode,
        'CNTRNECK_NECK_CODE': cntrneckNeckCode,
        'CNTRRTRN_RETURN_CODE': cntrrtrnReturnCode,
        'CNTRSIZE_SIZE_CODE': cntrsizeSizeCode,
        'CNTRTYPE_TYPE_CODE': cntrtypeTypeCode,
        'CONTAINR_ACTION_CODE': containrActionCode,
        'CONTAINR_APPROVAL_FLAG': containrApprovalFlag,
        'CONTAINR_CHANGE_COUNT': containrChangeCount,
        'CONTAINR_COMMENT': containrComment,
        'CONTAINR_EDIT_STATUS': containrEditStatus,
        'CONTAINR_EFFECTIVE_DATE': containrEffectiveDate,
        'CONTAINR_ID': containrId,
        'CONTAINR_INACTIVE_DATE': containrInactiveDate,
        'CONTAINR_POSTING_DATE': containrPostingDate,
        'CONTAINR_QUALIFIER': containrQualifier,
        'CONTAINR_STATUS_CODE': containrStatusCode,
        'CONTAINR_SYSTEM_FLAG': containrSystemFlag,
        'CONTAINR_USER_ID': containrUserId,
        'CONTAINR_VERSION_NO': containrVersionNo,
        'PGRAPHIC_GRAPHIC_CODE': pgraphicGraphicCode,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view dimequipmentstate
   * View Dimequipmentstate (SQL)
   * @returns view_dimequipmentstate successful operation
   * @throws ApiError
   */
  public getViewDimequipmentstate({
departmentName,
equipmentStateStatusAbbrev,
equipmentStateStatusCode,
equipmentStateStatusName,
etlInsertAccount,
insertDt,
isDowntimeFlag,
isUptimeFlag,
operationalStatusCode,
operationalStatusName,
operationalStatusNumber,
pkDimEquipmentState,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
departmentName?: string,
equipmentStateStatusAbbrev?: string,
/**
 * Format - int32.
 */
equipmentStateStatusCode?: number,
equipmentStateStatusName?: string,
etlInsertAccount?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertDt?: string,
isDowntimeFlag?: string,
isUptimeFlag?: string,
operationalStatusCode?: string,
operationalStatusName?: string,
/**
 * Format - int32.
 */
operationalStatusNumber?: number,
/**
 * Format - int32.
 */
pkDimEquipmentState?: number,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_dimequipmentstate>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/dimequipmentstate',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'DepartmentName': departmentName,
        'EquipmentStateStatusAbbrev': equipmentStateStatusAbbrev,
        'EquipmentStateStatusCode': equipmentStateStatusCode,
        'EquipmentStateStatusName': equipmentStateStatusName,
        'EtlInsertAccount': etlInsertAccount,
        'InsertDt': insertDt,
        'IsDowntimeFlag': isDowntimeFlag,
        'IsUptimeFlag': isUptimeFlag,
        'OperationalStatusCode': operationalStatusCode,
        'OperationalStatusName': operationalStatusName,
        'OperationalStatusNumber': operationalStatusNumber,
        'PKDimEquipmentState': pkDimEquipmentState,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view events
   * View - Events (Source: Kepware IoT)
   * @returns view_events successful operation
   * @throws ApiError
   */
  public getViewEvents({
area,
device,
enqueuedTime,
enqueuedTimeUtc,
equipment,
eventQualityValue,
eventTimestamp,
eventType,
eventTypeNumber,
eventValue,
messageTimestamp,
offset,
partition,
partitionKey,
publisher,
sequenceNumber,
site,
subArea,
tagName,
tagType,
insertGmtDt,
insertGmtMnthYr,
insertGmtTs,
srcFile,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
area?: string,
device?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
enqueuedTime?: string,
enqueuedTimeUtc?: string,
equipment?: string,
eventQualityValue?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
eventTimestamp?: string,
eventType?: string,
eventTypeNumber?: string,
eventValue?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
messageTimestamp?: string,
offset?: string,
partition?: string,
partitionKey?: string,
publisher?: string,
sequenceNumber?: string,
site?: string,
subArea?: string,
tagName?: string,
tagType?: string,
/**
 * Format - date (as full-date in RFC3339).
 */
insertGmtDt?: string,
insertGmtMnthYr?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
srcFile?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_events>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/events',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'area': area,
        'device': device,
        'enqueuedTime': enqueuedTime,
        'EnqueuedTimeUtc': enqueuedTimeUtc,
        'equipment': equipment,
        'event_quality_value': eventQualityValue,
        'event_timestamp': eventTimestamp,
        'event_type': eventType,
        'event_type_number': eventTypeNumber,
        'event_value': eventValue,
        'message_timestamp': messageTimestamp,
        'Offset': offset,
        'partition': partition,
        'partitionKey': partitionKey,
        'publisher': publisher,
        'SequenceNumber': sequenceNumber,
        'site': site,
        'sub_area': subArea,
        'tag_name': tagName,
        'tag_type': tagType,
        '__insert_gmt_dt': insertGmtDt,
        '__insert_gmt_mnth_yr': insertGmtMnthYr,
        '__insert_gmt_ts': insertGmtTs,
        '__src_file': srcFile,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view fault_events_brewery
   * Query view fault_events_brewery
   * @returns view_fault_events_brewery successful operation
   * @throws ApiError
   */
  public getViewFaultEventsBrewery({
brewery,
chunckTimestamp,
equipment,
eventName,
eventTimestamp,
eventType,
line,
sector,
tag,
dt,
refDay,
refHr,
refMth,
refYr,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
brewery?: string,
chunckTimestamp?: string,
equipment?: string,
eventName?: string,
eventTimestamp?: string,
eventType?: string,
line?: string,
sector?: string,
tag?: string,
dt?: string,
refDay?: string,
refHr?: string,
refMth?: string,
/**
 * Format - int32.
 */
refYr?: number,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_fault_events_brewery>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/fault_events_brewery',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'brewery': brewery,
        'chunck_timestamp': chunckTimestamp,
        'equipment': equipment,
        'event_name': eventName,
        'event_timestamp': eventTimestamp,
        'event_type': eventType,
        'line': line,
        'sector': sector,
        'tag': tag,
        '__dt': dt,
        '__ref_day': refDay,
        '__ref_hr': refHr,
        '__ref_mth': refMth,
        '__ref_yr': refYr,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view oracle_fof
   * Query view oracle_fof
   * @returns view_oracle_fof successful operation
   * @throws ApiError
   */
  public getViewOracleFof({
beginTime,
brwyCode,
downtimeHour,
duration,
equipment,
eventDate,
faultCode,
lineNo,
runDt,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
/**
 * Format - date-time (as date-time in RFC3339).
 */
beginTime?: string,
brwyCode?: string,
/**
 * Format - double.
 */
downtimeHour?: number,
/**
 * Format - double.
 */
duration?: number,
equipment?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
eventDate?: string,
/**
 * Format - double.
 */
faultCode?: number,
lineNo?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
runDt?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_oracle_fof>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/oracle_fof',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'BEGIN_TIME': beginTime,
        'BRWY_CODE': brwyCode,
        'DOWNTIME_HOUR': downtimeHour,
        'DURATION': duration,
        'EQUIPMENT': equipment,
        'EVENT_DATE': eventDate,
        'FAULT_CODE': faultCode,
        'LINE_NO': lineNo,
        'RUN_DT': runDt,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view plant
   * View - Plant Table (Source SQL)
   * @returns view_plant successful operation
   * @throws ApiError
   */
  public getViewPlant({
addressId,
afCode,
altCode,
code,
dayLightSavings,
imsCode,
name,
plantTypeCode,
regionCode,
sapCode,
stateProvinceCode,
timeZoneCode,
vportCode,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
/**
 * Format - double.
 */
addressId?: number,
afCode?: string,
altCode?: string,
code?: string,
dayLightSavings?: string,
imsCode?: string,
name?: string,
plantTypeCode?: string,
regionCode?: string,
sapCode?: string,
stateProvinceCode?: string,
timeZoneCode?: string,
vportCode?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_plant>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/plant',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'AddressId': addressId,
        'AFCode': afCode,
        'AltCode': altCode,
        'Code': code,
        'DayLightSavings': dayLightSavings,
        'IMSCode': imsCode,
        'Name': name,
        'PlantTypeCode': plantTypeCode,
        'RegionCode': regionCode,
        'SAPCode': sapCode,
        'StateProvinceCode': stateProvinceCode,
        'TimeZoneCode': timeZoneCode,
        'VPORTCode': vportCode,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view productbrewing
   * View - Product Brewing Table (Source SQL)
   * @returns view_productbrewing successful operation
   * @throws ApiError
   */
  public getViewProductbrewing({
active,
breweryProductTypeCode,
cmsProductCode,
code,
name,
productPackagingCode,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
active?: string,
breweryProductTypeCode?: string,
cmsProductCode?: string,
code?: string,
name?: string,
productPackagingCode?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_productbrewing>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/productbrewing',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'Active': active,
        'BreweryProductTypeCode': breweryProductTypeCode,
        'CMSProductCode': cmsProductCode,
        'Code': code,
        'Name': name,
        'ProductPackagingCode': productPackagingCode,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view prt_cd_dtl_hist
   * View - Prt cd dtl hist (Source Oracle)
   * @returns view_prt_cd_dtl_hist successful operation
   * @throws ApiError
   */
  public getViewPrtCdDtlHist({
actIndCd,
lastModId,
lastModTsp,
prtCdId,
prtCdSeqNbr,
vblCmpntCd,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
actIndCd?: string,
lastModId?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
lastModTsp?: string,
/**
 * Format - double.
 */
prtCdId?: number,
/**
 * Format - int32.
 */
prtCdSeqNbr?: number,
vblCmpntCd?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_prt_cd_dtl_hist>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/prt_cd_dtl_hist',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'ACT_IND_CD': actIndCd,
        'LAST_MOD_ID': lastModId,
        'LAST_MOD_TSP': lastModTsp,
        'PRT_CD_ID': prtCdId,
        'PRT_CD_SEQ_NBR': prtCdSeqNbr,
        'VBL_CMPNT_CD': vblCmpntCd,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view stck_cd_matl_hist
   * View - stck cd matl hist (Source Oracle)
   * @returns view_stck_cd_matl_hist successful operation
   * @throws ApiError
   */
  public getViewStckCdMatlHist({
actIndCd,
endDt,
lastModId,
lastModTsp,
pcsId,
prtCdId,
prtCdMatlId,
startDt,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
actIndCd?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
endDt?: string,
lastModId?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
lastModTsp?: string,
/**
 * Format - double.
 */
pcsId?: number,
/**
 * Format - double.
 */
prtCdId?: number,
/**
 * Format - double.
 */
prtCdMatlId?: number,
/**
 * Format - date-time (as date-time in RFC3339).
 */
startDt?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_stck_cd_matl_hist>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/stck_cd_matl_hist',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'ACT_IND_CD': actIndCd,
        'END_DT': endDt,
        'LAST_MOD_ID': lastModId,
        'LAST_MOD_TSP': lastModTsp,
        'PCS_ID': pcsId,
        'PRT_CD_ID': prtCdId,
        'PRT_CD_MATL_ID': prtCdMatlId,
        'START_DT': startDt,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view vbl_cmpnt_hist
   * View - vbl cmpnt  hist (Source Oracle)
   * @returns view_vbl_cmpnt_hist successful operation
   * @throws ApiError
   */
  public getViewVblCmpntHist({
actIndCd,
cmpntLngthNbr,
lastModId,
lastModTsp,
refIndCd,
vblCmpntCd,
vblCmpntDsc,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
actIndCd?: string,
/**
 * Format - int32.
 */
cmpntLngthNbr?: number,
lastModId?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
lastModTsp?: string,
refIndCd?: string,
vblCmpntCd?: string,
vblCmpntDsc?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_vbl_cmpnt_hist>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/vbl_cmpnt_hist',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'ACT_IND_CD': actIndCd,
        'CMPNT_LNGTH_NBR': cmpntLngthNbr,
        'LAST_MOD_ID': lastModId,
        'LAST_MOD_TSP': lastModTsp,
        'REF_IND_CD': refIndCd,
        'VBL_CMPNT_CD': vblCmpntCd,
        'VBL_CMPNT_DSC': vblCmpntDsc,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view vw_base_package
   * View -   vw_base_package (Source Oracle)
   * @returns view_vw_base_package successful operation
   * @throws ApiError
   */
  public getViewVwBasePackage({
containrId,
packageActionCode,
packageApprovalFlag,
packageBarrelAmt,
packageCaseAmt,
packageChangeCount,
packageComment,
packageContainerUnitQty,
packageEditStatus,
packageEffectiveDate,
packageId,
packageInactiveDate,
packageLiterAmt,
packagePalletStackAmt,
packagePostingDate,
packagePriceGroupCode,
packagePriceTaxCode,
packageQualifier,
packageStandardName,
packageStatusCode,
packageSystemFlag,
packageUnitPackageQty,
packageUserId,
packageVersionNo,
pkgcrtnMasterCartonCode,
pkgmklnMktgLineCode,
pkgwrcrWrapperCarrierCode,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
containrId?: string,
packageActionCode?: string,
packageApprovalFlag?: string,
/**
 * Format - double.
 */
packageBarrelAmt?: number,
/**
 * Format - double.
 */
packageCaseAmt?: number,
/**
 * Format - int64.
 */
packageChangeCount?: number,
packageComment?: string,
/**
 * Format - int64.
 */
packageContainerUnitQty?: number,
packageEditStatus?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
packageEffectiveDate?: string,
packageId?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
packageInactiveDate?: string,
/**
 * Format - double.
 */
packageLiterAmt?: number,
/**
 * Format - int64.
 */
packagePalletStackAmt?: number,
/**
 * Format - date-time (as date-time in RFC3339).
 */
packagePostingDate?: string,
packagePriceGroupCode?: string,
/**
 * Format - int64.
 */
packagePriceTaxCode?: number,
packageQualifier?: string,
packageStandardName?: string,
packageStatusCode?: string,
packageSystemFlag?: string,
/**
 * Format - int64.
 */
packageUnitPackageQty?: number,
packageUserId?: string,
/**
 * Format - int64.
 */
packageVersionNo?: number,
pkgcrtnMasterCartonCode?: string,
pkgmklnMktgLineCode?: string,
pkgwrcrWrapperCarrierCode?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_vw_base_package>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/vw_base_package',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'CONTAINR_ID': containrId,
        'PACKAGE_ACTION_CODE': packageActionCode,
        'PACKAGE_APPROVAL_FLAG': packageApprovalFlag,
        'PACKAGE_BARREL_AMT': packageBarrelAmt,
        'PACKAGE_CASE_AMT': packageCaseAmt,
        'PACKAGE_CHANGE_COUNT': packageChangeCount,
        'PACKAGE_COMMENT': packageComment,
        'PACKAGE_CONTAINER_UNIT_QTY': packageContainerUnitQty,
        'PACKAGE_EDIT_STATUS': packageEditStatus,
        'PACKAGE_EFFECTIVE_DATE': packageEffectiveDate,
        'PACKAGE_ID': packageId,
        'PACKAGE_INACTIVE_DATE': packageInactiveDate,
        'PACKAGE_LITER_AMT': packageLiterAmt,
        'PACKAGE_PALLET_STACK_AMT': packagePalletStackAmt,
        'PACKAGE_POSTING_DATE': packagePostingDate,
        'PACKAGE_PRICE_GROUP_CODE': packagePriceGroupCode,
        'PACKAGE_PRICE_TAX_CODE': packagePriceTaxCode,
        'PACKAGE_QUALIFIER': packageQualifier,
        'PACKAGE_STANDARD_NAME': packageStandardName,
        'PACKAGE_STATUS_CODE': packageStatusCode,
        'PACKAGE_SYSTEM_FLAG': packageSystemFlag,
        'PACKAGE_UNIT_PACKAGE_QTY': packageUnitPackageQty,
        'PACKAGE_USER_ID': packageUserId,
        'PACKAGE_VERSION_NO': packageVersionNo,
        'PKGCRTN_MASTER_CARTON_CODE': pkgcrtnMasterCartonCode,
        'PKGMKLN_MKTG_LINE_CODE': pkgmklnMktgLineCode,
        'PKGWRCR_WRAPPER_CARRIER_CODE': pkgwrcrWrapperCarrierCode,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view vw_brand_base_table
   * View - vw_brand_base_table (Source Oracle)
   * @returns view_vw_brand_base_table successful operation
   * @throws ApiError
   */
  public getViewVwBrandBaseTable({
brandActionCode,
brandApprovalFlag,
brandAsepticPkgFlag,
brandBrewedBrandCode,
brandBrewedProductCode,
brandChangeCount,
brandCode,
brandCompetitiveNbr,
brandEditStatus,
brandEffectiveDate,
brandFpcTaxCd,
brandImportFlag,
brandInactiveDate,
brandInternalNumber,
brandMarketBrandCode,
brandPostingDate,
brandProdTypCd,
brandShortName,
brandStandardName,
brandStatusCode,
brandSystemFlag,
brandTrademarkName,
brandTypeCode,
brandUserId,
brandVersionNo,
crftFlg,
fmbFlg,
hiEndFlg,
homeBrwrCd,
sesnlFlg,
smplPkFlg,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
brandActionCode?: string,
brandApprovalFlag?: string,
brandAsepticPkgFlag?: string,
brandBrewedBrandCode?: string,
brandBrewedProductCode?: string,
/**
 * Format - int32.
 */
brandChangeCount?: number,
brandCode?: string,
brandCompetitiveNbr?: string,
brandEditStatus?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
brandEffectiveDate?: string,
brandFpcTaxCd?: string,
brandImportFlag?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
brandInactiveDate?: string,
brandInternalNumber?: string,
brandMarketBrandCode?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
brandPostingDate?: string,
brandProdTypCd?: string,
brandShortName?: string,
brandStandardName?: string,
brandStatusCode?: string,
brandSystemFlag?: string,
brandTrademarkName?: string,
brandTypeCode?: string,
brandUserId?: string,
/**
 * Format - int64.
 */
brandVersionNo?: number,
crftFlg?: string,
fmbFlg?: string,
hiEndFlg?: string,
homeBrwrCd?: string,
sesnlFlg?: string,
smplPkFlg?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_vw_brand_base_table>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/vw_brand_base_table',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'BRAND_ACTION_CODE': brandActionCode,
        'BRAND_APPROVAL_FLAG': brandApprovalFlag,
        'BRAND_ASEPTIC_PKG_FLAG': brandAsepticPkgFlag,
        'BRAND_BREWED_BRAND_CODE': brandBrewedBrandCode,
        'BRAND_BREWED_PRODUCT_CODE': brandBrewedProductCode,
        'BRAND_CHANGE_COUNT': brandChangeCount,
        'BRAND_CODE': brandCode,
        'BRAND_COMPETITIVE_NBR': brandCompetitiveNbr,
        'BRAND_EDIT_STATUS': brandEditStatus,
        'BRAND_EFFECTIVE_DATE': brandEffectiveDate,
        'BRAND_FPC_TAX_CD': brandFpcTaxCd,
        'BRAND_IMPORT_FLAG': brandImportFlag,
        'BRAND_INACTIVE_DATE': brandInactiveDate,
        'BRAND_INTERNAL_NUMBER': brandInternalNumber,
        'BRAND_MARKET_BRAND_CODE': brandMarketBrandCode,
        'BRAND_POSTING_DATE': brandPostingDate,
        'BRAND_PROD_TYP_CD': brandProdTypCd,
        'BRAND_SHORT_NAME': brandShortName,
        'BRAND_STANDARD_NAME': brandStandardName,
        'BRAND_STATUS_CODE': brandStatusCode,
        'BRAND_SYSTEM_FLAG': brandSystemFlag,
        'BRAND_TRADEMARK_NAME': brandTrademarkName,
        'BRAND_TYPE_CODE': brandTypeCode,
        'BRAND_USER_ID': brandUserId,
        'BRAND_VERSION_NO': brandVersionNo,
        'CRFT_FLG': crftFlg,
        'FMB_FLG': fmbFlg,
        'HI_END_FLG': hiEndFlg,
        'HOME_BRWR_CD': homeBrwrCd,
        'SESNL_FLG': sesnlFlg,
        'SMPL_PK_FLG': smplPkFlg,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view vw_pkg_supt_matl
   * View - vw_pkg_supt_matl (Source Oracle)
   * @returns view_vw_pkg_supt_matl successful operation
   * @throws ApiError
   */
  public getViewVwPkgSuptMatl({
assemFlg,
auspModTsp,
baseUomCd,
cap1Txt,
cap2Txt,
cap3Txt,
expFlg,
issToEaFct,
lifoCat,
lifoCd,
maktModTsp,
maraModTsp,
marmModTsp,
mastModTsp,
matlDsc,
matlNbr,
matlStatus,
matlTypeCd,
modTsp,
nounTxt,
nqcModTsp,
obsFlg,
purchUomCd,
purToIssFct,
qualTxt,
sapMatlNbr,
zmatnrModTsp,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
assemFlg?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
auspModTsp?: string,
baseUomCd?: string,
cap1Txt?: string,
cap2Txt?: string,
cap3Txt?: string,
expFlg?: string,
/**
 * Format - int32.
 */
issToEaFct?: number,
lifoCat?: string,
lifoCd?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
maktModTsp?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
maraModTsp?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
marmModTsp?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
mastModTsp?: string,
matlDsc?: string,
matlNbr?: string,
matlStatus?: string,
matlTypeCd?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
modTsp?: string,
nounTxt?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
nqcModTsp?: string,
obsFlg?: string,
purchUomCd?: string,
/**
 * Format - int32.
 */
purToIssFct?: number,
qualTxt?: string,
sapMatlNbr?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
zmatnrModTsp?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_vw_pkg_supt_matl>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/vw_pkg_supt_matl',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'ASSEM_FLG': assemFlg,
        'AUSP_MOD_TSP': auspModTsp,
        'BASE_UOM_CD': baseUomCd,
        'CAP1_TXT': cap1Txt,
        'CAP2_TXT': cap2Txt,
        'CAP3_TXT': cap3Txt,
        'EXP_FLG': expFlg,
        'ISS_TO_EA_FCT': issToEaFct,
        'LIFO_CAT': lifoCat,
        'LIFO_CD': lifoCd,
        'MAKT_MOD_TSP': maktModTsp,
        'MARA_MOD_TSP': maraModTsp,
        'MARM_MOD_TSP': marmModTsp,
        'MAST_MOD_TSP': mastModTsp,
        'MATL_DSC': matlDsc,
        'MATL_NBR': matlNbr,
        'MATL_STATUS': matlStatus,
        'MATL_TYPE_CD': matlTypeCd,
        'MOD_TSP': modTsp,
        'NOUN_TXT': nounTxt,
        'NQC_MOD_TSP': nqcModTsp,
        'OBS_FLG': obsFlg,
        'PURCH_UOM_CD': purchUomCd,
        'PUR_TO_ISS_FCT': purToIssFct,
        'QUAL_TXT': qualTxt,
        'SAP_MATL_NBR': sapMatlNbr,
        'ZMATNR_MOD_TSP': zmatnrModTsp,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view vw_pkg_vndr_base
   * View - vw_pkg_vndr_base (Source Oracle)
   * @returns view_vw_pkg_vndr_base successful operation
   * @throws ApiError
   */
  public getViewVwPkgVndrBase({
addrTxt,
assocVndrNbr,
cityTxt,
delFlg,
lfa1ModTsp,
lfb1ModTsp,
lfm1ModTsp,
mmsVndrNbr,
modTsp,
payToVndrNbr,
repackFlg,
sapVndrNbr,
stCd,
vndrBarcode,
vndrNbr,
vndrNm,
vndrTypeCd,
wyt3ModTsp,
zipCd,
zmuvBarcodeModTsp,
zvendorXrefModTsp,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
addrTxt?: string,
assocVndrNbr?: string,
cityTxt?: string,
delFlg?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
lfa1ModTsp?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
lfb1ModTsp?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
lfm1ModTsp?: string,
mmsVndrNbr?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
modTsp?: string,
payToVndrNbr?: string,
repackFlg?: string,
sapVndrNbr?: string,
stCd?: string,
/**
 * Format - int32.
 */
vndrBarcode?: number,
vndrNbr?: string,
vndrNm?: string,
vndrTypeCd?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
wyt3ModTsp?: string,
zipCd?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
zmuvBarcodeModTsp?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
zvendorXrefModTsp?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_vw_pkg_vndr_base>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/vw_pkg_vndr_base',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'ADDR_TXT': addrTxt,
        'ASSOC_VNDR_NBR': assocVndrNbr,
        'CITY_TXT': cityTxt,
        'DEL_FLG': delFlg,
        'LFA1_MOD_TSP': lfa1ModTsp,
        'LFB1_MOD_TSP': lfb1ModTsp,
        'LFM1_MOD_TSP': lfm1ModTsp,
        'MMS_VNDR_NBR': mmsVndrNbr,
        'MOD_TSP': modTsp,
        'PAY_TO_VNDR_NBR': payToVndrNbr,
        'REPACK_FLG': repackFlg,
        'SAP_VNDR_NBR': sapVndrNbr,
        'ST_CD': stCd,
        'VNDR_BARCODE': vndrBarcode,
        'VNDR_NBR': vndrNbr,
        'VNDR_NM': vndrNm,
        'VNDR_TYPE_CD': vndrTypeCd,
        'WYT3_MOD_TSP': wyt3ModTsp,
        'ZIP_CD': zipCd,
        'ZMUV_BARCODE_MOD_TSP': zmuvBarcodeModTsp,
        'ZVENDOR_XREF_MOD_TSP': zvendorXrefModTsp,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view vw_stock_cd_base
   * View -   vw_stock_cd_base (Source Oracle)
   * @returns view_vw_stock_cd_base successful operation
   * @throws ApiError
   */
  public getViewVwStockCdBase({
actnCd,
exprtDomstIndCd,
lastModId,
lastModTsp,
stockCd,
stockCdDsc,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
actnCd?: string,
exprtDomstIndCd?: string,
lastModId?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
lastModTsp?: string,
stockCd?: string,
stockCdDsc?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_vw_stock_cd_base>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/vw_stock_cd_base',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'ACTN_CD': actnCd,
        'EXPRT_DOMST_IND_CD': exprtDomstIndCd,
        'LAST_MOD_ID': lastModId,
        'LAST_MOD_TSP': lastModTsp,
        'STOCK_CD': stockCd,
        'STOCK_CD_DSC': stockCdDsc,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view brewdat_fof_last_24
   * View - First on Fault recorded in last 24 hours (Source: PLC Data via Brewdat)
   * @returns view_brewdat_fof_last_24 successful operation
   * @throws ApiError
   */
  public getViewBrewdatFofLast24({
area,
downTimeDate,
downTimeDuration,
equipment,
faultCode,
faultCodeProximity,
faultCount,
faultDuration,
faultEndTimeLocal,
faultStartTimeLocal,
firstEndState,
firstStartState,
fullEquipment,
otherFaults,
site,
stateEndTimeLocal,
stateStartDateLocal,
stateStartTimeLocal,
subArea,
warningCount,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
area?: string,
/**
 * Format - date (as full-date in RFC3339).
 */
downTimeDate?: string,
/**
 * Format - double.
 */
downTimeDuration?: number,
equipment?: string,
faultCode?: string,
/**
 * Format - int32.
 */
faultCodeProximity?: number,
/**
 * Format - int64.
 */
faultCount?: number,
/**
 * Format - double.
 */
faultDuration?: number,
/**
 * Format - date-time (as date-time in RFC3339).
 */
faultEndTimeLocal?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
faultStartTimeLocal?: string,
firstEndState?: string,
firstStartState?: string,
fullEquipment?: string,
otherFaults?: string,
site?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
stateEndTimeLocal?: string,
/**
 * Format - date (as full-date in RFC3339).
 */
stateStartDateLocal?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
stateStartTimeLocal?: string,
subArea?: string,
/**
 * Format - int64.
 */
warningCount?: number,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_brewdat_fof_last_24>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/brewdat_fof_last_24',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'area': area,
        'down_time_date': downTimeDate,
        'down_time_duration': downTimeDuration,
        'equipment': equipment,
        'fault_code': faultCode,
        'fault_code_proximity': faultCodeProximity,
        'fault_count': faultCount,
        'fault_duration': faultDuration,
        'fault_end_time_local': faultEndTimeLocal,
        'fault_start_time_local': faultStartTimeLocal,
        'first_end_state': firstEndState,
        'first_start_state': firstStartState,
        'full_equipment': fullEquipment,
        'other_faults': otherFaults,
        'site': site,
        'state_end_time_local': stateEndTimeLocal,
        'state_start_date_local': stateStartDateLocal,
        'state_start_time_local': stateStartTimeLocal,
        'sub_area': subArea,
        'warning_count': warningCount,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view prt_cd_hist
   * View - Prt cd hist (Source Oracle)
   * @returns view_prt_cd_hist successful operation
   * @throws ApiError
   */
  public getViewPrtCdHist({
actIndCd,
ageCmpntId,
lastModId,
lastModTsp,
prtCdDsc,
prtCdId,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
actIndCd?: string,
/**
 * Format - int32.
 */
ageCmpntId?: number,
lastModId?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
lastModTsp?: string,
prtCdDsc?: string,
/**
 * Format - int32.
 */
prtCdId?: number,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_prt_cd_hist>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/prt_cd_hist',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'ACT_IND_CD': actIndCd,
        'AGE_CMPNT_ID': ageCmpntId,
        'LAST_MOD_ID': lastModId,
        'LAST_MOD_TSP': lastModTsp,
        'PRT_CD_DSC': prtCdDsc,
        'PRT_CD_ID': prtCdId,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view equipmentmaster
   * View - Equipment Master Table (Source SQL)
   * @returns view_equipmentmaster successful operation
   * @throws ApiError
   */
  public getViewEquipmentmaster({
actualHoursL365,
area,
areaCode,
areaType,
bestPracticeFlag,
bestPracticeRecipe,
bomChanged,
bomCount,
bomCreated,
catProf,
changedBy,
changedOn,
configMat,
constType,
constTypeDesc,
conY,
costCtr,
countryCode,
createdBy,
createdOn,
criticalFlag,
criticalRankingAbc,
departmentCode,
docCnt,
edwDesc,
emergencyOrderByEquipL365,
equipCategory,
equipmentDescription,
equipmentNumber,
equipmentStatus,
estimatedRtsCost,
estimatedRtsDate,
everactiveFlag,
functionalLocationCode,
ih08SysStatus,
inventoryNumber,
levatasFlag,
location,
manufacturerName,
manufSerialNumber,
mnWrkCtr,
modelNumber,
numMaintItems,
objectNum,
objectType,
oos,
orderByEquipL365,
partCostL365,
plantAlternateCode,
plantCode,
plantSection,
plantType,
pos,
processedDate,
processGroup,
regionCode,
semeqFlag,
servoFlag,
sortField,
stateOrProvince,
superEq,
superiorFunctionalLocation,
superiorOrderEquipment,
teamRoom,
techId,
telemetryFlag,
validDocCnt,
venWrtyEnd,
venWrtySrt,
workOrderNo,
zteFunctionalLocation,
zteObjectType,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
/**
 * Format - double.
 */
actualHoursL365?: number,
area?: string,
areaCode?: string,
areaType?: string,
bestPracticeFlag?: string,
bestPracticeRecipe?: string,
/**
 * Format - double.
 */
bomChanged?: number,
/**
 * Format - double.
 */
bomCount?: number,
/**
 * Format - double.
 */
bomCreated?: number,
catProf?: string,
changedBy?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
changedOn?: string,
configMat?: string,
constType?: string,
constTypeDesc?: string,
conY?: string,
costCtr?: string,
countryCode?: string,
createdBy?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
createdOn?: string,
criticalFlag?: string,
criticalRankingAbc?: string,
departmentCode?: string,
/**
 * Format - double.
 */
docCnt?: number,
edwDesc?: string,
/**
 * Format - double.
 */
emergencyOrderByEquipL365?: number,
equipCategory?: string,
equipmentDescription?: string,
/**
 * Format - double.
 */
equipmentNumber?: number,
equipmentStatus?: string,
/**
 * Format - double.
 */
estimatedRtsCost?: number,
/**
 * Format - date-time (as date-time in RFC3339).
 */
estimatedRtsDate?: string,
everactiveFlag?: string,
functionalLocationCode?: string,
ih08SysStatus?: string,
inventoryNumber?: string,
levatasFlag?: string,
location?: string,
manufacturerName?: string,
manufSerialNumber?: string,
mnWrkCtr?: string,
modelNumber?: string,
/**
 * Format - double.
 */
numMaintItems?: number,
objectNum?: string,
objectType?: string,
oos?: string,
/**
 * Format - double.
 */
orderByEquipL365?: number,
/**
 * Format - double.
 */
partCostL365?: number,
plantAlternateCode?: string,
plantCode?: string,
plantSection?: string,
plantType?: string,
pos?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
processedDate?: string,
processGroup?: string,
regionCode?: string,
semeqFlag?: string,
servoFlag?: string,
sortField?: string,
stateOrProvince?: string,
superEq?: string,
superiorFunctionalLocation?: string,
/**
 * Format - double.
 */
superiorOrderEquipment?: number,
teamRoom?: string,
techId?: string,
telemetryFlag?: string,
/**
 * Format - double.
 */
validDocCnt?: number,
/**
 * Format - date-time (as date-time in RFC3339).
 */
venWrtyEnd?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
venWrtySrt?: string,
/**
 * Format - double.
 */
workOrderNo?: number,
zteFunctionalLocation?: string,
zteObjectType?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_equipmentmaster>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/equipmentmaster',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'ActualHoursL365': actualHoursL365,
        'Area': area,
        'AreaCode': areaCode,
        'AreaType': areaType,
        'BestPracticeFlag': bestPracticeFlag,
        'BestPracticeRecipe': bestPracticeRecipe,
        'BOMChanged': bomChanged,
        'BOMCount': bomCount,
        'BOMCreated': bomCreated,
        'CatProf': catProf,
        'ChangedBy': changedBy,
        'ChangedOn': changedOn,
        'ConfigMat': configMat,
        'ConstType': constType,
        'ConstTypeDesc': constTypeDesc,
        'ConY': conY,
        'CostCtr': costCtr,
        'CountryCode': countryCode,
        'CreatedBy': createdBy,
        'CreatedOn': createdOn,
        'CriticalFlag': criticalFlag,
        'CriticalRankingABC': criticalRankingAbc,
        'DepartmentCode': departmentCode,
        'DocCnt': docCnt,
        'EDW_Desc': edwDesc,
        'EmergencyOrderByEquipL365': emergencyOrderByEquipL365,
        'EquipCategory': equipCategory,
        'EquipmentDescription': equipmentDescription,
        'EquipmentNumber': equipmentNumber,
        'EquipmentStatus': equipmentStatus,
        'EstimatedRtsCost': estimatedRtsCost,
        'EstimatedRtsDate': estimatedRtsDate,
        'EveractiveFlag': everactiveFlag,
        'FunctionalLocationCode': functionalLocationCode,
        'IH08SysStatus': ih08SysStatus,
        'InventoryNumber': inventoryNumber,
        'LevatasFlag': levatasFlag,
        'Location': location,
        'ManufacturerName': manufacturerName,
        'ManufSerialNumber': manufSerialNumber,
        'MnWrkCtr': mnWrkCtr,
        'ModelNumber': modelNumber,
        'NumMaintItems': numMaintItems,
        'ObjectNum': objectNum,
        'ObjectType': objectType,
        'OOS': oos,
        'OrderByEquipL365': orderByEquipL365,
        'PartCostL365': partCostL365,
        'PlantAlternateCode': plantAlternateCode,
        'PlantCode': plantCode,
        'PlantSection': plantSection,
        'PlantType': plantType,
        'Pos': pos,
        'ProcessedDate': processedDate,
        'ProcessGroup': processGroup,
        'RegionCode': regionCode,
        'SEMEQFlag': semeqFlag,
        'ServoFlag': servoFlag,
        'SortField': sortField,
        'StateOrProvince': stateOrProvince,
        'SuperEq': superEq,
        'SuperiorFunctionalLocation': superiorFunctionalLocation,
        'SuperiorOrderEquipment': superiorOrderEquipment,
        'Team_Room': teamRoom,
        'TechID': techId,
        'TelemetryFlag': telemetryFlag,
        'ValidDocCnt': validDocCnt,
        'VenWrtyEnd': venWrtyEnd,
        'VenWrtySrt': venWrtySrt,
        'WorkOrderNO': workOrderNo,
        'ZTEFunctionalLocation': zteFunctionalLocation,
        'ZTEObjectType': zteObjectType,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view vw_base_product
   * View - vw_base_product (Source Oracle)
   * @returns view_vw_base_product successful operation
   * @throws ApiError
   */
  public getViewVwBaseProduct({
actAlchlStrengthCd,
alcoholStrengthCode,
brandCode,
productActionCode,
productApprovalFlag,
productChangeCount,
productEditStatus,
productEffectiveDate,
productId,
productInactiveDate,
productPostingDate,
productStatusCode,
productSystemFlag,
productUserId,
productVersionNo,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
actAlchlStrengthCd?: string,
alcoholStrengthCode?: string,
brandCode?: string,
productActionCode?: string,
productApprovalFlag?: string,
/**
 * Format - int64.
 */
productChangeCount?: number,
productEditStatus?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
productEffectiveDate?: string,
productId?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
productInactiveDate?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
productPostingDate?: string,
productStatusCode?: string,
productSystemFlag?: string,
productUserId?: string,
/**
 * Format - int64.
 */
productVersionNo?: number,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_vw_base_product>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/vw_base_product',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'ACT_ALCHL_STRENGTH_CD': actAlchlStrengthCd,
        'ALCOHOL_STRENGTH_CODE': alcoholStrengthCode,
        'BRAND_CODE': brandCode,
        'PRODUCT_ACTION_CODE': productActionCode,
        'PRODUCT_APPROVAL_FLAG': productApprovalFlag,
        'PRODUCT_CHANGE_COUNT': productChangeCount,
        'PRODUCT_EDIT_STATUS': productEditStatus,
        'PRODUCT_EFFECTIVE_DATE': productEffectiveDate,
        'PRODUCT_ID': productId,
        'PRODUCT_INACTIVE_DATE': productInactiveDate,
        'PRODUCT_POSTING_DATE': productPostingDate,
        'PRODUCT_STATUS_CODE': productStatusCode,
        'PRODUCT_SYSTEM_FLAG': productSystemFlag,
        'PRODUCT_USER_ID': productUserId,
        'PRODUCT_VERSION_NO': productVersionNo,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view vw_codes_base
   * View - vw_codes_base (Source Oracle)
   * @returns view_vw_codes_base successful operation
   * @throws ApiError
   */
  public getViewVwCodesBase({
code,
codeActionCode,
codeDataAlpha1,
codeDataAlpha2,
codeDataNum1,
codeDataNum2,
codeDescription,
codeEffectiveDate,
codeInactiveDate,
codePkNextval,
codeStatusCode,
codeTypeCode,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
code?: string,
codeActionCode?: string,
codeDataAlpha1?: string,
codeDataAlpha2?: string,
/**
 * Format - double.
 */
codeDataNum1?: number,
/**
 * Format - double.
 */
codeDataNum2?: number,
codeDescription?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
codeEffectiveDate?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
codeInactiveDate?: string,
/**
 * Format - double.
 */
codePkNextval?: number,
codeStatusCode?: string,
codeTypeCode?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_vw_codes_base>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/vw_codes_base',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'CODE': code,
        'CODE_ACTION_CODE': codeActionCode,
        'CODE_DATA_ALPHA1': codeDataAlpha1,
        'CODE_DATA_ALPHA2': codeDataAlpha2,
        'CODE_DATA_NUM1': codeDataNum1,
        'CODE_DATA_NUM2': codeDataNum2,
        'CODE_DESCRIPTION': codeDescription,
        'CODE_EFFECTIVE_DATE': codeEffectiveDate,
        'CODE_INACTIVE_DATE': codeInactiveDate,
        'CODE_PK_NEXTVAL': codePkNextval,
        'CODE_STATUS_CODE': codeStatusCode,
        'CODE_TYPE_CODE': codeTypeCode,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view vw_mbew_base_table
   * View - vw_mbew_base_table (Source Oracle)
   * @returns view_vw_mbew_base_table successful operation
   * @throws ApiError
   */
  public getViewVwMbewBaseTable({
abciw,
abwkz,
bklas,
bwkey,
bwpei,
bwph1,
bwprh,
bwprs,
bwps1,
bwspa,
bwtar,
bwtty,
bwva1,
bwva2,
bwva3,
ekalr,
eklas,
hkmat,
hrkft,
kalkl,
kalkv,
kalkz,
kaln1,
kalnr,
kalsc,
kosgr,
kziwl,
laepr,
lbkum,
lfgja,
lfmon,
lplpr,
lvorm,
mandt,
matnr,
mlast,
mlmaa,
modTsp,
mtorg,
mtuse,
mypol,
ownpr,
pdatl,
pdatv,
pdatz,
peinh,
pperl,
pperv,
pperz,
pprdl,
pprdv,
pprdz,
pstat,
qklas,
salk3,
salkv,
sperw,
stprs,
stprv,
verpr,
vers1,
vers2,
vers3,
vjbkl,
vjbwh,
vjbws,
vjkum,
vjpei,
vjsal,
vjsav,
vjstp,
vjver,
vjvpr,
vksal,
vmbkl,
vmkum,
vmpei,
vmsal,
vmsav,
vmstp,
vmver,
vmvpr,
vplpr,
vprsv,
vvjlb,
vvjsl,
vvmlb,
vvsal,
wlinl,
xlifo,
zkdat,
zkprs,
zpld1,
zpld2,
zpld3,
zplp1,
zplp2,
zplp3,
zplpr,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
abciw?: string,
abwkz?: string,
bklas?: string,
bwkey?: string,
/**
 * Format - double.
 */
bwpei?: number,
/**
 * Format - double.
 */
bwph1?: number,
/**
 * Format - double.
 */
bwprh?: number,
/**
 * Format - double.
 */
bwprs?: number,
/**
 * Format - double.
 */
bwps1?: number,
/**
 * Format - double.
 */
bwspa?: number,
bwtar?: string,
bwtty?: string,
bwva1?: string,
bwva2?: string,
bwva3?: string,
ekalr?: string,
eklas?: string,
hkmat?: string,
hrkft?: string,
kalkl?: string,
kalkv?: string,
kalkz?: string,
kaln1?: string,
kalnr?: string,
kalsc?: string,
kosgr?: string,
kziwl?: string,
laepr?: string,
/**
 * Format - double.
 */
lbkum?: number,
lfgja?: string,
lfmon?: string,
/**
 * Format - double.
 */
lplpr?: number,
lvorm?: string,
mandt?: string,
matnr?: string,
mlast?: string,
mlmaa?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
modTsp?: string,
mtorg?: string,
mtuse?: string,
mypol?: string,
ownpr?: string,
pdatl?: string,
pdatv?: string,
pdatz?: string,
/**
 * Format - double.
 */
peinh?: number,
pperl?: string,
pperv?: string,
pperz?: string,
pprdl?: string,
pprdv?: string,
pprdz?: string,
pstat?: string,
qklas?: string,
/**
 * Format - double.
 */
salk3?: number,
/**
 * Format - double.
 */
salkv?: number,
sperw?: string,
/**
 * Format - double.
 */
stprs?: number,
/**
 * Format - double.
 */
stprv?: number,
/**
 * Format - double.
 */
verpr?: number,
vers1?: string,
vers2?: string,
vers3?: string,
vjbkl?: string,
/**
 * Format - double.
 */
vjbwh?: number,
/**
 * Format - double.
 */
vjbws?: number,
/**
 * Format - double.
 */
vjkum?: number,
/**
 * Format - double.
 */
vjpei?: number,
/**
 * Format - double.
 */
vjsal?: number,
/**
 * Format - double.
 */
vjsav?: number,
/**
 * Format - double.
 */
vjstp?: number,
/**
 * Format - double.
 */
vjver?: number,
vjvpr?: string,
/**
 * Format - double.
 */
vksal?: number,
vmbkl?: string,
/**
 * Format - double.
 */
vmkum?: number,
/**
 * Format - double.
 */
vmpei?: number,
/**
 * Format - double.
 */
vmsal?: number,
/**
 * Format - double.
 */
vmsav?: number,
/**
 * Format - double.
 */
vmstp?: number,
/**
 * Format - double.
 */
vmver?: number,
vmvpr?: string,
/**
 * Format - double.
 */
vplpr?: number,
vprsv?: string,
/**
 * Format - double.
 */
vvjlb?: number,
/**
 * Format - double.
 */
vvjsl?: number,
/**
 * Format - double.
 */
vvmlb?: number,
/**
 * Format - double.
 */
vvsal?: number,
wlinl?: string,
xlifo?: string,
zkdat?: string,
/**
 * Format - double.
 */
zkprs?: number,
zpld1?: string,
zpld2?: string,
zpld3?: string,
/**
 * Format - double.
 */
zplp1?: number,
/**
 * Format - double.
 */
zplp2?: number,
/**
 * Format - double.
 */
zplp3?: number,
/**
 * Format - double.
 */
zplpr?: number,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_vw_mbew_base_table>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/vw_mbew_base_table',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'ABCIW': abciw,
        'ABWKZ': abwkz,
        'BKLAS': bklas,
        'BWKEY': bwkey,
        'BWPEI': bwpei,
        'BWPH1': bwph1,
        'BWPRH': bwprh,
        'BWPRS': bwprs,
        'BWPS1': bwps1,
        'BWSPA': bwspa,
        'BWTAR': bwtar,
        'BWTTY': bwtty,
        'BWVA1': bwva1,
        'BWVA2': bwva2,
        'BWVA3': bwva3,
        'EKALR': ekalr,
        'EKLAS': eklas,
        'HKMAT': hkmat,
        'HRKFT': hrkft,
        'KALKL': kalkl,
        'KALKV': kalkv,
        'KALKZ': kalkz,
        'KALN1': kaln1,
        'KALNR': kalnr,
        'KALSC': kalsc,
        'KOSGR': kosgr,
        'KZIWL': kziwl,
        'LAEPR': laepr,
        'LBKUM': lbkum,
        'LFGJA': lfgja,
        'LFMON': lfmon,
        'LPLPR': lplpr,
        'LVORM': lvorm,
        'MANDT': mandt,
        'MATNR': matnr,
        'MLAST': mlast,
        'MLMAA': mlmaa,
        'MOD_TSP': modTsp,
        'MTORG': mtorg,
        'MTUSE': mtuse,
        'MYPOL': mypol,
        'OWNPR': ownpr,
        'PDATL': pdatl,
        'PDATV': pdatv,
        'PDATZ': pdatz,
        'PEINH': peinh,
        'PPERL': pperl,
        'PPERV': pperv,
        'PPERZ': pperz,
        'PPRDL': pprdl,
        'PPRDV': pprdv,
        'PPRDZ': pprdz,
        'PSTAT': pstat,
        'QKLAS': qklas,
        'SALK3': salk3,
        'SALKV': salkv,
        'SPERW': sperw,
        'STPRS': stprs,
        'STPRV': stprv,
        'VERPR': verpr,
        'VERS1': vers1,
        'VERS2': vers2,
        'VERS3': vers3,
        'VJBKL': vjbkl,
        'VJBWH': vjbwh,
        'VJBWS': vjbws,
        'VJKUM': vjkum,
        'VJPEI': vjpei,
        'VJSAL': vjsal,
        'VJSAV': vjsav,
        'VJSTP': vjstp,
        'VJVER': vjver,
        'VJVPR': vjvpr,
        'VKSAL': vksal,
        'VMBKL': vmbkl,
        'VMKUM': vmkum,
        'VMPEI': vmpei,
        'VMSAL': vmsal,
        'VMSAV': vmsav,
        'VMSTP': vmstp,
        'VMVER': vmver,
        'VMVPR': vmvpr,
        'VPLPR': vplpr,
        'VPRSV': vprsv,
        'VVJLB': vvjlb,
        'VVJSL': vvjsl,
        'VVMLB': vvmlb,
        'VVSAL': vvsal,
        'WLINL': wlinl,
        'XLIFO': xlifo,
        'ZKDAT': zkdat,
        'ZKPRS': zkprs,
        'ZPLD1': zpld1,
        'ZPLD2': zpld2,
        'ZPLD3': zpld3,
        'ZPLP1': zplp1,
        'ZPLP2': zplp2,
        'ZPLP3': zplp3,
        'ZPLPR': zplpr,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view vw_pkg_bom_zbs
   * View - vw_pkg_bom_zbs (Source Oracle)
   * @returns view_vw_pkg_bom_zbs successful operation
   * @throws ApiError
   */
  public getViewVwPkgBomZbs({
hdrBomUsageCd,
hdrDelFlg,
hdrEffDt,
hdrIneffDt,
hdrMatlDsc,
hdrMatlNbr,
hdrMatlType,
hdrModTsp,
hdrPkgLineNbr,
hdrPlantCd,
hdrQty,
hdrStatusCd,
hdrUom,
itemBrewFlg,
itemCntNbr,
itemEffDt,
itemIneffDt,
itemInStockCd,
itemMatlDsc,
itemMatlNbr,
itemModTsp,
itemOperScrapFctNbr,
itemPerfUsageQty,
itemScrapFctNbr,
itemUom,
modTsp,
sapHdrMatlNbr,
sapItemMatlNbr,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
hdrBomUsageCd?: string,
hdrDelFlg?: string,
hdrEffDt?: string,
hdrIneffDt?: string,
hdrMatlDsc?: string,
hdrMatlNbr?: string,
hdrMatlType?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
hdrModTsp?: string,
hdrPkgLineNbr?: string,
hdrPlantCd?: string,
/**
 * Format - double.
 */
hdrQty?: number,
hdrStatusCd?: string,
hdrUom?: string,
itemBrewFlg?: string,
itemCntNbr?: string,
itemEffDt?: string,
itemIneffDt?: string,
itemInStockCd?: string,
itemMatlDsc?: string,
itemMatlNbr?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
itemModTsp?: string,
/**
 * Format - double.
 */
itemOperScrapFctNbr?: number,
/**
 * Format - double.
 */
itemPerfUsageQty?: number,
/**
 * Format - double.
 */
itemScrapFctNbr?: number,
itemUom?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
modTsp?: string,
sapHdrMatlNbr?: string,
sapItemMatlNbr?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_vw_pkg_bom_zbs>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/vw_pkg_bom_zbs',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'HDR_BOM_USAGE_CD': hdrBomUsageCd,
        'HDR_DEL_FLG': hdrDelFlg,
        'HDR_EFF_DT': hdrEffDt,
        'HDR_INEFF_DT': hdrIneffDt,
        'HDR_MATL_DSC': hdrMatlDsc,
        'HDR_MATL_NBR': hdrMatlNbr,
        'HDR_MATL_TYPE': hdrMatlType,
        'HDR_MOD_TSP': hdrModTsp,
        'HDR_PKG_LINE_NBR': hdrPkgLineNbr,
        'HDR_PLANT_CD': hdrPlantCd,
        'HDR_QTY': hdrQty,
        'HDR_STATUS_CD': hdrStatusCd,
        'HDR_UOM': hdrUom,
        'ITEM_BREW_FLG': itemBrewFlg,
        'ITEM_CNT_NBR': itemCntNbr,
        'ITEM_EFF_DT': itemEffDt,
        'ITEM_INEFF_DT': itemIneffDt,
        'ITEM_IN_STOCK_CD': itemInStockCd,
        'ITEM_MATL_DSC': itemMatlDsc,
        'ITEM_MATL_NBR': itemMatlNbr,
        'ITEM_MOD_TSP': itemModTsp,
        'ITEM_OPER_SCRAP_FCT_NBR': itemOperScrapFctNbr,
        'ITEM_PERF_USAGE_QTY': itemPerfUsageQty,
        'ITEM_SCRAP_FCT_NBR': itemScrapFctNbr,
        'ITEM_UOM': itemUom,
        'MOD_TSP': modTsp,
        'SAP_HDR_MATL_NBR': sapHdrMatlNbr,
        'SAP_ITEM_MATL_NBR': sapItemMatlNbr,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view vw_prod_cont_stck_cd_hist_zbs
   * View - vw_prod_cont_stck_cd_hist_zbs (Source Oracle)
   * @returns view_vw_prod_cont_stck_cd_hist_zbs successful operation
   * @throws ApiError
   */
  public getViewVwProdContStckCdHistZbs({
actIndCd,
cmntTxt,
contCd,
lastModId,
lastModTsp,
pcsId,
prodCd,
stckCd,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
actIndCd?: string,
cmntTxt?: string,
contCd?: string,
lastModId?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
lastModTsp?: string,
/**
 * Format - double.
 */
pcsId?: number,
prodCd?: string,
stckCd?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_vw_prod_cont_stck_cd_hist_zbs>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/vw_prod_cont_stck_cd_hist_zbs',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'ACT_IND_CD': actIndCd,
        'CMNT_TXT': cmntTxt,
        'CONT_CD': contCd,
        'LAST_MOD_ID': lastModId,
        'LAST_MOD_TSP': lastModTsp,
        'PCS_ID': pcsId,
        'PROD_CD': prodCd,
        'STCK_CD': stckCd,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

  /**
   * Query view vw_prod_pkg_base
   * View - vw_prod_pkg_base (Source Oracle)
   * @returns view_vw_prod_pkg_base successful operation
   * @throws ApiError
   */
  public getViewVwProdPkgBase({
fullUpcCarrierCd,
fullUpcCaseCd,
fullUpcUnitCd,
gtinCarrierId,
gtinCaseId,
gtinUnitId,
packageId,
pgraphicGraphicCode,
prodpkgActionCode,
prodpkgApprovalFlag,
prodpkgChangeCount,
prodpkgCountryCode,
prodpkgDeleteEligibleFlag,
prodpkgEditStatus,
prodpkgEffectiveDate,
prodpkgId,
prodpkgInactiveDate,
prodpkgPostingDate,
prodpkgStatusCode,
prodpkgSystemFlag,
prodpkgUpcCarrierCode,
prodpkgUpcCarrierFlag,
prodpkgUpcCaseCode,
prodpkgUpcCaseFlag,
prodpkgUpcSuppressFlag,
prodpkgUpcUnitCode,
prodpkgUpcUnitFlag,
prodpkgUserId,
prodpkgVersionNo,
productId,
insertGmtTs,
updateGmtTs,
resultsLimit = 100,
resultsOffset,
nested = 'false',
apiNestedFormatProperties = 'false',
}: {
fullUpcCarrierCd?: string,
fullUpcCaseCd?: string,
fullUpcUnitCd?: string,
gtinCarrierId?: string,
gtinCaseId?: string,
gtinUnitId?: string,
packageId?: string,
pgraphicGraphicCode?: string,
prodpkgActionCode?: string,
prodpkgApprovalFlag?: string,
/**
 * Format - double.
 */
prodpkgChangeCount?: number,
prodpkgCountryCode?: string,
prodpkgDeleteEligibleFlag?: string,
prodpkgEditStatus?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
prodpkgEffectiveDate?: string,
prodpkgId?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
prodpkgInactiveDate?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
prodpkgPostingDate?: string,
prodpkgStatusCode?: string,
prodpkgSystemFlag?: string,
prodpkgUpcCarrierCode?: string,
prodpkgUpcCarrierFlag?: string,
prodpkgUpcCaseCode?: string,
prodpkgUpcCaseFlag?: string,
prodpkgUpcSuppressFlag?: string,
prodpkgUpcUnitCode?: string,
prodpkgUpcUnitFlag?: string,
prodpkgUserId?: string,
prodpkgVersionNo?: string,
productId?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
insertGmtTs?: string,
/**
 * Format - date-time (as date-time in RFC3339).
 */
updateGmtTs?: string,
/**
 * Max number of results
 */
resultsLimit?: number,
/**
 * Results offset
 */
resultsOffset?: number,
/**
 * Nest results using timbr relationships
 */
nested?: string,
/**
 * Format nested properties based on api tags
 */
apiNestedFormatProperties?: string,
}): CancelablePromise<Array<view_vw_prod_pkg_base>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/view/vw_prod_pkg_base',
      headers: {
        'results-limit': resultsLimit,
        'results-offset': resultsOffset,
        'nested': nested,
        'api-nested-format-properties': apiNestedFormatProperties,
      },
      query: {
        'FULL_UPC_CARRIER_CD': fullUpcCarrierCd,
        'FULL_UPC_CASE_CD': fullUpcCaseCd,
        'FULL_UPC_UNIT_CD': fullUpcUnitCd,
        'GTIN_CARRIER_ID': gtinCarrierId,
        'GTIN_CASE_ID': gtinCaseId,
        'GTIN_UNIT_ID': gtinUnitId,
        'PACKAGE_ID': packageId,
        'PGRAPHIC_GRAPHIC_CODE': pgraphicGraphicCode,
        'PRODPKG_ACTION_CODE': prodpkgActionCode,
        'PRODPKG_APPROVAL_FLAG': prodpkgApprovalFlag,
        'PRODPKG_CHANGE_COUNT': prodpkgChangeCount,
        'PRODPKG_COUNTRY_CODE': prodpkgCountryCode,
        'PRODPKG_DELETE_ELIGIBLE_FLAG': prodpkgDeleteEligibleFlag,
        'PRODPKG_EDIT_STATUS': prodpkgEditStatus,
        'PRODPKG_EFFECTIVE_DATE': prodpkgEffectiveDate,
        'PRODPKG_ID': prodpkgId,
        'PRODPKG_INACTIVE_DATE': prodpkgInactiveDate,
        'PRODPKG_POSTING_DATE': prodpkgPostingDate,
        'PRODPKG_STATUS_CODE': prodpkgStatusCode,
        'PRODPKG_SYSTEM_FLAG': prodpkgSystemFlag,
        'PRODPKG_UPC_CARRIER_CODE': prodpkgUpcCarrierCode,
        'PRODPKG_UPC_CARRIER_FLAG': prodpkgUpcCarrierFlag,
        'PRODPKG_UPC_CASE_CODE': prodpkgUpcCaseCode,
        'PRODPKG_UPC_CASE_FLAG': prodpkgUpcCaseFlag,
        'PRODPKG_UPC_SUPPRESS_FLAG': prodpkgUpcSuppressFlag,
        'PRODPKG_UPC_UNIT_CODE': prodpkgUpcUnitCode,
        'PRODPKG_UPC_UNIT_FLAG': prodpkgUpcUnitFlag,
        'PRODPKG_USER_ID': prodpkgUserId,
        'PRODPKG_VERSION_NO': prodpkgVersionNo,
        'PRODUCT_ID': productId,
        '__insert_gmt_ts': insertGmtTs,
        '__update_gmt_ts': updateGmtTs,
      },
      errors: {
        500: `Error running query`,
      },
    });
  }

}
