import { ABIAppButton, ButtonSize } from "@ab-inbev-labs/ux-react-components";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

type UnrecoverableErrorPageProps = {
  messageI18Key?: string;
};

const UnrecoverableErrorPage: FC<UnrecoverableErrorPageProps> = ({
  messageI18Key = "runtimeExceptionMessage",
}) => {
  const i18next = useTranslation();
  const location = useLocation();

  const [initialPath, setInitialPath] = useState("");

  // Keep track of the URL. If it changes from the user clicking on the breadcrumb,
  // reload the page to reset the error state.
  useEffect(() => {
    if (!initialPath) setInitialPath(location.pathname);
    else if (initialPath !== location.pathname) window.location.reload();
  }, [initialPath, location.pathname]);

  return (
    <>
      <div>{i18next.t(messageI18Key)}</div>
      <ABIAppButton
        className="refresh-button"
        isNotCapitalized
        size={ButtonSize.lg}
        onClick={() => window.location.reload()}
      >
        {i18next.t("refreshPage")}
      </ABIAppButton>
    </>
  );
};

export default UnrecoverableErrorPage;
