/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetGeneralSettingsResponse } from '../models/GetGeneralSettingsResponse';
import type { SaveGeneralSettingsRequest } from '../models/SaveGeneralSettingsRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GeneralSettingsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get general settings
   * Gets the user's general settings
   * @returns GetGeneralSettingsResponse Success
   * @throws ApiError
   */
  public generalSettingsGet(): CancelablePromise<GetGeneralSettingsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/general-settings',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Save general settings
   * Saves the user's general settings
   * @returns any Success
   * @throws ApiError
   */
  public generalSettingsSave({
requestBody,
}: {
requestBody?: SaveGeneralSettingsRequest,
}): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/general-settings',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
