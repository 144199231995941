import {
  ABIAccordion,
  ABIAccordionItem,
  ABIAccordionItemHeading,
  ABIAccordionItemPanel,
  ABIActionDrawer,
  ABIAppButton,
  ABIInlineLoader,
  ABISearchBar,
  AccordionIconPosition,
  DrawerContainerPosition,
  ExpandMoreIcon,
} from "@ab-inbev-labs/ux-react-components";
import {
  UserProfileQueryKeys,
  useGeneralSettingsGetQuery,
  useGeneralSettingsSaveMutation,
} from "@ab-inbev/sam-api/clients/user-profile-api-client";
import { useQueryClient } from "@tanstack/react-query";
import { FC, ReactElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import beerMug from "../assets/beerMug.png";
import "./UserProfile.scss";
import { UserContext } from "@ab-inbev/sam-auth";
import {
  findBrewery,
  loadStaticBreweriesList,
  selectBreweryListAsSearchBar,
  useBreweryListQueryWithFallback,
} from "@ab-inbev/sam-api/clients/brew-data-api-client";
import { LanguageOptions, UnitsOptions } from "./userProfileSettings/types";
import { UserSettingButtons } from "./userProfileSettings/UserSettingButtons";
import { getLanguageNameDisplay } from "../utils/languageUtils";
import {
  LanguageSettings,
  UnitsSettings,
} from "./userProfileSettings/constants";

export type UserProfileProps = {
  additionalSettings?: ReactElement;
};

export const UserProfile: FC<UserProfileProps> = ({ additionalSettings }) => {
  const { t, i18n } = useTranslation();

  // API query definitions.
  const queryClient = useQueryClient();
  const {
    data: loadedSettingsData,
    isLoading,
    isError,
  } = useGeneralSettingsGetQuery();
  const { mutateAsync } = useGeneralSettingsSaveMutation();
  const { data: ApiBreweryOptions } = useBreweryListQueryWithFallback(
    loadStaticBreweriesList(),
    selectBreweryListAsSearchBar,
  );

  const [isUserProfileActionDrawerOpen, setIsUserProfileActionDrawerOpen] =
    useState(false);

  // Settings state variables
  const userContext = useContext(UserContext);
  const userDisplayName = userContext?.user?.name ?? "";
  const [refreshSettings, setRefreshSettings] = useState(true);
  const [languageSetting, setLanguageSetting] = useState<string>(
    LanguageOptions.English,
  );
  const [brewerySetting, setBrewerySetting] = useState<string>("");
  const [unitsSetting, setUnitsSetting] = useState<string>(
    UnitsOptions.Imperial,
  );

  //#region User Profile API Calls

  // GET settings
  useEffect(() => {
    if (refreshSettings && loadedSettingsData) {
      setRefreshSettings(false);

      if (loadedSettingsData.breweryCode !== brewerySetting) {
        setBrewerySetting(loadedSettingsData.breweryCode ?? "");
      }

      if (loadedSettingsData.language !== languageSetting) {
        setLanguageSetting(
          loadedSettingsData.language ?? LanguageOptions.English,
        );
      }

      if (loadedSettingsData.units !== unitsSetting) {
        setUnitsSetting(loadedSettingsData.units ?? "");
      }
    }
  }, [
    refreshSettings,
    loadedSettingsData,
    brewerySetting,
    languageSetting,
    unitsSetting,
    i18n,
  ]);

  const invalidateSettingsQuery = async () =>
    await queryClient.invalidateQueries({
      queryKey: [UserProfileQueryKeys.UseGeneralSettingsGet],
    });

  // PUT settings updates.
  const updateUserProfileSettings = async (
    language: string,
    breweryCode: string,
    units: string,
  ) => {
    await mutateAsync(
      {
        language,
        breweryCode,
        units,
      },
      {
        onSuccess: async () => {
          await invalidateSettingsQuery();
          setRefreshSettings(true);
        },
      },
    );
  };

  // Handle button updates.
  useEffect(() => {
    if (!refreshSettings) {
      updateUserProfileSettings(languageSetting, brewerySetting, unitsSetting);
      i18n.changeLanguage(languageSetting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageSetting, brewerySetting, unitsSetting]);

  // #endregion

  // #region User Profile Action Drawer Button
  const userProfileActionDrawerButton = (
    <>
      <img
        className="user-profile-action-drawer-button-profile-image"
        src={beerMug}
        alt="Default 'Mug of Beer' Profile Icon"
      />
      <p className="user-profile-action-drawer-button-username">
        {userDisplayName}
      </p>
      <ExpandMoreIcon
        className={`user-profile-action-drawer-button-dropdown-icon${
          isUserProfileActionDrawerOpen ? "-open " : " "
        }`}
      />
    </>
  );
  // #endregion

  // #region Brewery Selection Accordion
  const brewerySelectionAccordion = (
    <ABIAccordionItem className="user-profile-brewery-selection-accordion">
      <ABIAccordionItemHeading className="user-profile-brewery-selection-accordion-heading-label">
        {`${t("userProfile.brewerySelectionLabel")}${
          brewerySetting !== "" ? " - " + findBrewery(brewerySetting) : ""
        }`}
      </ABIAccordionItemHeading>
      <ABIAccordionItemPanel>
        <ABISearchBar
          placeholder={t("userProfile.brewerySelectionSearchPlaceholder")}
          isBorderLight
          selectedValue={
            brewerySetting !== "" ? findBrewery(brewerySetting) : undefined
          }
          suggestions={ApiBreweryOptions}
          allowSuggestionsOnly
          onChange={(event) => {
            if (event.includes("(") && event.includes(")")) {
              const selectedBrewCode = event
                .substring(event.lastIndexOf("(") + 1, event.lastIndexOf(")"))
                .trim();
              updateUserProfileSettings(
                languageSetting,
                selectedBrewCode,
                unitsSetting,
              );
            } else if (event === "") {
              updateUserProfileSettings(languageSetting, "", unitsSetting);
            }
          }}
        />
      </ABIAccordionItemPanel>
    </ABIAccordionItem>
  );
  // #endregion

  // #region Language and Units Accordion

  const getUnitsNameDisplay = (): string => {
    if (unitsSetting === UnitsOptions.Metric) {
      return t("userProfile.metricUnitsSelection");
    }

    return t("userProfile.imperialUnitsSelection");
  };

  const languageAccordion = (
    <ABIAccordionItem className={`user-profile-setting-accordion `}>
      <ABIAccordionItemHeading className="user-profile-setting-accordion-heading-label">
        {`${t("userProfile.languageLabel")} - ${getLanguageNameDisplay(
          languageSetting as LanguageOptions,
        )}`}
      </ABIAccordionItemHeading>
      <ABIAccordionItemPanel>
        <UserSettingButtons
          settingButtonData={LanguageSettings}
          currentSetting={languageSetting}
          updateCurrentSetting={setLanguageSetting}
        />
      </ABIAccordionItemPanel>
    </ABIAccordionItem>
  );

  const unitsAccordion = (
    <ABIAccordionItem
      className={`user-profile-setting-accordion ${
        additionalSettings ? "user-settings-section-break" : ""
      }`}
    >
      <ABIAccordionItemHeading className="user-profile-setting-accordion-heading-label">
        {`${t("userProfile.unitsLabel")} - ${getUnitsNameDisplay()}`}
      </ABIAccordionItemHeading>
      <ABIAccordionItemPanel>
        <UserSettingButtons
          settingButtonData={UnitsSettings}
          currentSetting={unitsSetting}
          updateCurrentSetting={setUnitsSetting}
        />
      </ABIAccordionItemPanel>
    </ABIAccordionItem>
  );

  // #endregion

  const drawerContent = () => {
    if (isLoading)
      return (
        <div className="user-profile-loader">
          <ABIInlineLoader />
        </div>
      );
    else if (isError)
      return (
        <div className="user-profile-error-content">
          <div className="user-profile-error-message">
            {t("userProfile.errorMessage")}
          </div>
          <ABIAppButton onClick={async () => await invalidateSettingsQuery()}>
            {t("userProfile.tryAgain")}
          </ABIAppButton>
        </div>
      );
    else
      return (
        <ABIAccordion
          allowMultipleExpanded={true}
          allowZeroExpanded={true}
          iconPosition={AccordionIconPosition.Left}
        >
          {brewerySelectionAccordion}
          {languageAccordion}
          {unitsAccordion}
          {additionalSettings || <></>}
        </ABIAccordion>
      );
  };

  return (
    <ABIActionDrawer
      buttonClassName="user-profile-action-drawer-button"
      buttonContent={userProfileActionDrawerButton}
      className="user-profile-action-drawer"
      closeOnContainerClick={false}
      drawerClassName="user-profile-action-drawer-content"
      drawerContainerPosition={DrawerContainerPosition.BottomRight}
      isDrawerInitiallyOpen={isUserProfileActionDrawerOpen}
      onButtonClick={() =>
        setIsUserProfileActionDrawerOpen(!isUserProfileActionDrawerOpen)
      }
      width="320px"
    >
      {drawerContent()}
    </ABIActionDrawer>
  );
};
