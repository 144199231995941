export enum LanguageOptions {
  English = "en-US",
  French = "fr-CA",
}

export enum UnitsOptions {
  Imperial = "imperial",
  Metric = "metric",
}

export type SettingButtonData = {
  buttonText: string;
  buttonValue: string;
};
