import {
  ABIAppBar,
  ABIBreadcrumb,
  LightOrDark,
  useURLPath,
} from "@ab-inbev-labs/ux-react-components";
import { FC, ReactElement, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { SidebarContext } from "../context/SidebarContext";
import { LayoutSize, useLayoutSize } from "../hooks/useLayoutSize";
import { UserProfile } from "./UserProfile";
import { HelpMenu } from "./HelpMenu";
import { LogoutButton } from "./LogoutButton";

type AppBarWithBreadcrumbProps = {
  appTitle: string;
  isThisRenderedInvisibleOnWebpagePrint: boolean;
  showUserProfile?: boolean;
  additionalUserProfileSettings?: ReactElement;
};

const generateBaseBreadcrumb = (path: string, t: (key: string) => string) => {
  const baseBreadcrumb = [
    { label: t("url.home"), to: `${window.location.origin}` },
  ];
  if (path !== "/apps" && path !== "/") {
    baseBreadcrumb.push({ label: t("url.apps"), to: "/apps" });
  }
  return baseBreadcrumb;
};

const AppBarWithBreadcrumb: FC<AppBarWithBreadcrumbProps> = ({
  appTitle,
  isThisRenderedInvisibleOnWebpagePrint,
  showUserProfile = true,
  additionalUserProfileSettings,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const urlBreadcrumb = useURLPath({ capitalize: false });
  const layoutSize = useLayoutSize();
  const { doesCurrentApplicationUseSidebar } = useContext(SidebarContext);

  const breadcrumbItems = useMemo(() => {
    const baseBreadcrumb = generateBaseBreadcrumb(location.pathname, t);
    return [
      ...baseBreadcrumb,
      ...urlBreadcrumb.map((urlItem) => ({
        ...urlItem,
        label: t(`url.${urlItem.label}`, { defaultValue: urlItem.label }),
      })),
    ];
  }, [location.pathname, t, urlBreadcrumb]);

  const breadcrumbComponent = useMemo(
    () => (
      <ABIBreadcrumb
        separator=">"
        theme={LightOrDark.Light}
        className={isThisRenderedInvisibleOnWebpagePrint ? "no-print" : ""}
      >
        {breadcrumbItems.map(({ to, label }) => (
          <Link key={to} to={to}>
            {label}
          </Link>
        ))}
      </ABIBreadcrumb>
    ),
    [breadcrumbItems, isThisRenderedInvisibleOnWebpagePrint],
  );

  const classNames =
    "sam-header " +
    `${doesCurrentApplicationUseSidebar ? "sam-header-with-sidebar" : ""} ` +
    `${isThisRenderedInvisibleOnWebpagePrint ? "no-print" : ""} `;

  return (
    <ABIAppBar
      appTitle={appTitle}
      theme={LightOrDark.Light}
      breadcrumb={layoutSize !== LayoutSize.Mobile && breadcrumbComponent}
      className={classNames}
    >
      <div className="user-profile-actions">
        <HelpMenu />

        {showUserProfile && (
          <UserProfile additionalSettings={additionalUserProfileSettings} />
        )}

        {showUserProfile && <LogoutButton />}
      </div>
    </ABIAppBar>
  );
};

export default AppBarWithBreadcrumb;
