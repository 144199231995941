import React from "react";
import { useRouteError } from "react-router-dom";
import UnrecoverableErrorPage from "@ab-inbev/sam-layout/components/UnrecoverableErrorPage";
import { ApiError } from "@ab-inbev/sam-api";

// This is not truly an error boundary, as it does not check for an error and conditionally
// render its children, but instead it will be rendered by react-router when an error occurs
export function ErrorBoundary() {
  const error = useRouteError();

  // Determine the error message to be shown. 'TypeError - Failed to fetch' or 'TypeError - NetworkError ...'
  // indicates the API could not be reached for some reason, and if we received an ApiError, the API returned
  // some status code that could not be handled by the business logic e.g. 500.
  const networkError = () =>
    ((error as Error).name === "TypeError" &&
      ((error as Error).message === "Failed to fetch" ||
        (error as Error).message.includes("NetworkError"))) ||
    (error as ApiError).status;

  return (
    <UnrecoverableErrorPage
      messageI18Key={
        networkError() ? "networkErrorMessage" : "runtimeExceptionMessage"
      }
    />
  );
}
